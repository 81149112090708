import { useState, useEffect } from "react";
import { get, post } from "superagent";

import Header from "../shared/header";
import ErrorAlert from "../shared/errorAlert";
import ActionSuccess from "../shared/actionSuccess";
import { Case, Page, Document } from "../../types";

function TrashPage() {
  const [error, setError] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [deletedCaseStudies, setDeletedCaseStudies] = useState<Array<Case>>([]);
  const [deletedPages, setDeletedPages] = useState<Array<Page>>([]);
  const [deletedDocs, setDeletedDocs] = useState<Array<Document>>([]);

  // Get all case studies
  const getDeletedCaseStudies = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/trash`)
      .withCredentials()
      .then((res: any) => {
        setDeletedCaseStudies(res.body.cases);
        setDeletedPages(res.body.pages);
        setDeletedDocs(res.body.documents);
        return;
      }).catch((err: any) => {
        if (err.response && err.response.text) {
          setError(err.response.text)
        }
      });
  };

  const restore = async (itemType: "case"|"page"|"document", itemId: number) => {
    await post(`${process.env.REACT_APP_API_URL}/api/trash/restore/${itemType}/${itemId}`)
      .withCredentials()
      .then((res: any) => {
        // Update case studies
        getDeletedCaseStudies();
        setShowSuccessToast(true);
        return;
      }).catch((err: any) => {
        if (err.response && err.response.text) {
          setError(err.response.text)
        }
      });
  };

  const getDates = (dateTime: string) => {
    const deleteDate: any = new Date(dateTime);
    const now: any = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const daysLeftToResore = Math.round(30 - (Math.abs((now - deleteDate) / oneDay))) + " days";
    const readableDeleteDate = `${deleteDate.getMonth()}/${deleteDate.getDate()}/${deleteDate.getFullYear()}`
    return {readableDeleteDate, daysLeftToResore};
  };

  useEffect(() => {
    getDeletedCaseStudies();
  }, []);

  return (
    <div>

      <Header title="Case Study Builder" isPaused={false}/>
      
      <main role="main">

        <div className="container">

          <h1>Trash Bin</h1>
          <p>Items on this page can be recovered up to 30 days after deletion. They will be <b>permanently deleted</b> after 30 days.</p>
          
          <ErrorAlert errorMessage={error}/>

          <ActionSuccess 
            show={showSuccessToast} 
            message={"Item has been restored!"}
            setShow={setShowSuccessToast}
          />

          <h2>Case Studies</h2>
          {deletedCaseStudies.length > 0 ?
            
            <div className="row trashed row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 case-studies-cards">
              {deletedCaseStudies.map((cs: Case, i) => {
                const {readableDeleteDate, daysLeftToResore} = getDates(cs.deletionDate)
                return (
                  <div className="col" key={i}>
                    <div className="card h-100">
                      <div className="card-header">
                        <h2>{cs.name}</h2>
                      </div>
                      <div className="card-body">
                        <p>{cs.description}</p>
                        <p><b>Deleted on: </b> {readableDeleteDate}
                        <br/>
                        <b>Time left to restore:</b> {daysLeftToResore}</p>
                        <button 
                          onClick={() => restore("case", cs.id)} 
                          className="btn btn-success case-delete">
                            Restore
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            : <p>You have no deleted case studies.</p>
          }

          <h2>Pages</h2>
            {deletedPages.length > 0 ?
              
              <div className="row trashed trashed-page case-studies-cards">
                {deletedPages.map((p: Page, i) => {
                  const {readableDeleteDate, daysLeftToResore} = getDates(p.deletionDate)
                  return (
                    <div className="col" key={i}>
                      <div className="card h-100">
                        <div className="card-body">
                          <div className="d-flex">
                            <h2>{p.title ? p.title : "Untitled"}</h2> &nbsp;&nbsp; | &nbsp;&nbsp;
                            <b>Case study:&nbsp;</b> "{p.case.name}"
                          </div>
                          <p><b>Deleted on: </b> {readableDeleteDate}
                          <br/>
                          <b>Time left to restore:</b> {daysLeftToResore} days</p>
                          <button 
                            onClick={() => restore("page", p.id)} 
                            className="btn btn-success case-delete">
                              Restore
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              : <p>You have no deleted pages.</p>
            }


          <h2>Documents</h2>

          {deletedDocs.length > 0 ? 
            <table className="table table-striped document-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Delete date</th>
                  <th>Time to restore</th>
                  <th>Case study</th>
                  <th>Page</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {deletedDocs.map((d) => {
                  const {readableDeleteDate, daysLeftToResore} = getDates(d.deletionDate)
                  return <tr key={d.id}>
                    <td>{d.title}</td>
                    <td>{readableDeleteDate}</td>
                    <td>{daysLeftToResore}</td>
                    <td>{d.page.case.name}</td>
                    <td>{d.page.title ? d.page.title : "Untitled"}</td>
                    <td align="right">
                      <button className="btn btn-sm btn-success" onClick={() => restore("document", d.id)}>
                        Restore
                      </button>
                      </td>
                  </tr>
                })}
              </tbody>
            </table>
            : "You have no deleted documents."}

          <br/>

        </div>

      </main>

    </div>
  );
}

export default TrashPage;
