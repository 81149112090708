
import {  useState } from "react";
import { Form, Row, InputGroup, Button } from "react-bootstrap";
import { post } from "superagent";

import Header from "./header";

function Login() {

  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const login = async (e: any) => {
    const { username, password } = values;
    e.preventDefault();
    e.stopPropagation();
    await post(`${process.env.REACT_APP_API_URL}/api/login/`)
      .withCredentials()
      .send({username, password})
      .then(((res: any) => {
        if (res.body.role) {
          setValidated(true);
          // Redirect to student or instructor pages
          if (res.body.role) {
            window.location.replace(`/account/cases`);
          }
        }
        else {
          setError("There was an error logging in.");
        }
      }), (err: any) => {
        if (err.response && err.response.text) {
          if (err.response.text === "Unauthorized") {
            return setError("Invalid username or password.");
          }
          setError(err.response.text);
        }
        else {
          setError("There was an unknown issue logging in.");
        }
        setValidated(false);
      })
  };

  return (
    <div>
    
      <Header title="Log into Case Study" isPaused={false}/>

      <main role="main">

        <div className="container">

          <div className="row justify-content-md-center">
            <div className="col-5">
              <h2>Welcome, please log in</h2>
              <br/>
              <Form validated={validated} onSubmit={login}>
                <Row className="mb-3">

                  <Form.Group controlId="validationUsername">
                    <Form.Label>Username</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="username"
                        placeholder="Username"
                        aria-describedby="inputGroupPrepend"
                        onChange={(e) => setValues((values) => ({
                          ...values,
                          username: e.target.value,
                        }))}
                        required
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId="validationPassword">
                    <br/>
                    <Form.Label>Password</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="password"
                        placeholder="password"
                        aria-describedby="inputGroupPrepend"
                        onChange={(e) => setValues((values) => ({
                          ...values,
                          password: e.target.value,
                        }))}
                        required
                      />

                    </InputGroup>
                  </Form.Group>
                </Row>
                {error ? <div className="text-danger">{error}<br/><br/></div> : null }
                <p><small><a href="/forgot-password">I forgot my password</a></small></p>
                <br/>
                <Button type="submit" onClick={login}>Login</Button>
              </Form>

            </div>
          </div>
        </div>

      </main>

    </div>
  );

}

export default Login;
