
import { useEffect, useState } from "react";
import { Form, Row, InputGroup, Button } from "react-bootstrap";
import { useParams } from "react-router";
import { patch } from "superagent";

import Header from "./header";

function PasswordResetByToken() {

  let { token, userId, userName } = useParams();
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const passwordsMatch = (password && passwordConfirm) && password === passwordConfirm;

  const login = async (e: any) => {
    e.preventDefault();
    await patch(`${process.env.REACT_APP_API_URL}/api/user/password/token/${userId}`)
      .send({token, password})
      .withCredentials()
      .then((res: any) => {
        if (res.body.success) {
          setSuccess(true);
        }
      }).catch(err => {
        console.log("ERR", err)
        if (err.response && err.response.text) {
          console.log(err);
          setError(err.response.text);
        }
        else {
          setError("There was an issue creating your password. Please contact your administrator.")
        }
      })
  };

  const validatePassword = () => {
    var passReqRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
    const passwordsValid = passReqRegex.test(password) && passReqRegex.test(passwordConfirm);

    if (passwordsMatch && passwordsValid) {
      setValidated(true);
    } 
    else {
      setValidated(false);
    }
    
  }

  useEffect(() => {
    validatePassword();
  }, [password, passwordConfirm]);

  return (
    <div>
    
      <Header title="Log into Case Study" isPaused={false}/>

      <main role="main">

        {success ? 
          <div className="container" style={{textAlign: "center"}}>
            Your password has been saved. You can now <a href="/">Log into your account</a>.
          </div> :
          <div className="container">

            <div className="row justify-content-md-center">
              <div className="col-5">

                <div>
                  <h3>Create Password</h3>
                  <p>Welcome, <b>{userName}</b>, to the Case Study app! Please create a password for your account.</p>
                </div>

                <Form validated={validated} onSubmit={login}>
                  <Row className="mb-3">

                    <Form.Group controlId="validationPassword">
                      <Form.Label>New password</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          required/>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="validationPasswordConfirm">
                      <br/>
                      <Form.Label>Confirm new password</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                          required/>
                      </InputGroup>

                    </Form.Group>
                  </Row>

                  <p className="text-secondary">
                    <b>Requirements: </b>Password must be a minimum of 6 characters long and contain at least one number and one symbol.
                  </p>

                  <ul>
                    <li 
                      style={{fontWeight: "bold"}} 
                      className={`${passwordsMatch ? "text-success" : "text-danger"}`}>
                        Passwords match &nbsp;
                        <i className={`bi bi-${passwordsMatch ? "check-circle" : "x-circle"}`}/>
                    </li>
                    <li 
                      style={{fontWeight: "bold"}} 
                      className={`${validated ? "text-success" : "text-danger"}`}>
                        Password requirements met &nbsp;
                        <i className={`bi bi-${validated ? "check-circle" : "x-circle"}`}/>
                      </li>
                  </ul>

                  <div className="text-danger">{error}</div><br />

                  <Button type="submit" onClick={login} disabled={!validated}>Save</Button>
                </Form>

              </div>
            </div>
          </div>
        }

      </main>

    </div>
  );

}

export default PasswordResetByToken;
