import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { get, post, del, patch } from "superagent";

import { User, Role } from "../../types";
import Loader from "../shared/loader";
import Header from "../shared/header";
import ErrorAlert from "../shared/errorAlert";
import ActionSuccess from "../shared/actionSuccess";
import PaginationControls from "../shared/paginationControls";

interface NewUser {
  "userName": string,
  "email": string,
  "roleId": number
}

const blankUser = {
  "userName": "", 
  "email": "", 
  "roleId": 1
}

const maxUsersPerPage = 10;

function UsersPage() {

  const loggedInUser: User = useSelector((state: any) => state.user);
  const [users, setUsers] = useState<Array<User>>([]);
  const [roles, setRoles] = useState<Array<Role>>([]);
  const [error, setError] = useState<string>("");
  const [addUserError, setAddUserError] = useState<string>("");
  const [newUser, setNewuser] = useState<NewUser>(blankUser);
  const [currentUserId, setCurrentUserId] = useState<number|null>(null);
  const [showEditMode, setShowEditMode] = useState<boolean>(false);
  const [showNewUserModal, setShowNewUserModal] = useState<boolean>(false);
  const [showConfirmdeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [showConfirmSendPwReset, setShowConfirmSendPwReset] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [roleFilter, setRoleFilter] = useState<number>(0);
  const [keywordFilter, setKeywordFilter] = useState<string>("");
  const currentUser = users.find(u => currentUserId === u.id);
  const filteredUsers = users.filter(u => {
    return (!roleFilter || roleFilter === u.roleId) && 
    (!keywordFilter || u.userName.includes(keywordFilter) || u.email.includes(keywordFilter))
  });
  const totalPages = Math.ceil(filteredUsers.length / maxUsersPerPage);
  const pageStartIndex = currentPage * maxUsersPerPage;
  const pageEndIndex = (currentPage * maxUsersPerPage) + maxUsersPerPage;

  const getUsers = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/user/`)
    .withCredentials()
    .then((res: any) => { setUsers(res.body) }).catch((err: any) => {
      if (err.response && err.response.text) {
        setError(err.response.text)
      }
    });
  }

  const getRoles = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/role/`)
    .withCredentials()
    .then((res: any) => { setRoles(res.body) }).catch((err: any) => {
      if (err.response && err.response.text) {
        setError(err.response.text)
      }
    });
  }

  const handleUpdateUser = (
    field: "userName"|"email"|"roleId", 
    e: React.ChangeEvent<any>
  ) => {
    const newUserCopy: any = Object.assign({}, newUser);
    newUserCopy[field] = e.target.value;
    setNewuser(newUserCopy);
  }

  const addUser = async (e: React.FormEvent) => {
    setIsLoading(true);
    e.preventDefault();
    setAddUserError("");
    await post(`${process.env.REACT_APP_API_URL}/api/user/`)
    .withCredentials()
    .send(newUser)
    .then((res: any) => {
      setShowNewUserModal(false);
      setShowSuccessToast(true);
      setSuccessMessage("New user created");
      getUsers();
    }).catch((err: any) => {
      if (err.response && err.response.text) {
        setAddUserError(err.response.text)
      }
    });
    setIsLoading(false);
  }

  const deleteUser = async () => {
    await del(`${process.env.REACT_APP_API_URL}/api/user/${currentUserId}`)
    .withCredentials()
    .then((res: any) => {
      setShowConfirmDeleteModal(false);
      setShowSuccessToast(true);
      setSuccessMessage("User deleted");
      getUsers();
    }).catch((err: any) => {
      if (err.response && err.response.text) {
        setError(err.response.text)
      }
    });
  }

  const saveUser = async (e: React.FormEvent) => {
    e.preventDefault();
    await patch(`${process.env.REACT_APP_API_URL}/api/user/${currentUserId}`)
    .withCredentials()
    .send(newUser)
    .then((res: any) => {
      setCurrentUserId(null);
      setShowSuccessToast(true);
      setSuccessMessage("User updated");
      getUsers();
    }).catch((err: any) => {
      if (err.response && err.response.text) {
        setError(err.response.text)
      }
    });
  }

  const sendPasswordReset = async () => {
    setIsLoading(true);
    const userName = users.find(u => u.id == currentUserId)?.userName;
    await post(`${process.env.REACT_APP_API_URL}/api/email/token/${userName}`)
    .withCredentials()
    .then((res: any) => {
      setCurrentUserId(null);
      setShowSuccessToast(true);
      setSuccessMessage("Email sent");
    }).catch((err: any) => {
      if (err.response && err.response.text) {
        setError(err.response.text)
      }
    });
    setShowConfirmSendPwReset(false);
    setIsLoading(false);
  }

  useEffect(() => {
    getUsers();
    getRoles();
  }, []);

  return (
    <div>
      <Header title="Case Study Builder" isPaused={false}/>
      
      <div className="container">
        <div className="d-flex justify-content-between align-items-end">
          <h1>Manage Users</h1>
          <button 
            className="btn btn-primary mt-2" 
            onClick={() => {
              setCurrentUserId(null);
              setNewuser(blankUser);
              setShowNewUserModal(true)
            }}>
            New User +
            </button>
        </div>

        <ErrorAlert errorMessage={error}/>

        <br/>

        <div className="user-searchbar">
          <label>Search:</label>
          <input 
            className="form-control" 
            value={keywordFilter} 
            onChange={e => setKeywordFilter(e.target.value)}/>
          <Form.Select 
            className="form-control" 
            value={roleFilter}
            onChange={(e) => setRoleFilter(parseInt(e.target.value))}>
            <option value={0}>All roles</option>
            {roles.map(r => {
              return (
                <option key={r.id} value={r.id}>{r.name}</option>
              )
            })}
          </Form.Select>
          <button 
            className="btn btn-sm btn-light"
            title="Clear filters"
            onClick={() => { setKeywordFilter(""); setRoleFilter(0); }}>
            <i className="bi bi-x-lg"/>
            </button>

        </div>

        <Form onSubmit={saveUser}>
          <table className="table table-hover users-table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                <th className="centered">Edit</th>
                <th className="centered">Remove</th>
                <th className="centered">Password Reset</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.slice(pageStartIndex, pageEndIndex).map(user => {
                if (showEditMode && currentUserId === user.id) {
                  return (
                    <tr key={user.id}>
                      <td>
                        <input 
                          type="text" 
                          required
                          className="form-control" 
                          defaultValue={user.userName}
                          onChange={(e) => handleUpdateUser("userName", e)}/>
                        </td>
                        <td>
                        <input 
                          type="email" 
                          required
                          className="form-control" 
                          defaultValue={user.email}
                          onChange={(e) => handleUpdateUser("email", e)}/>
                        </td>
                        <td>
                        {user.id !== loggedInUser.id ?
                          <Form.Select 
                            defaultValue={user.roleId} 
                            required
                            onChange={(e) => handleUpdateUser("roleId", e)}>
                            {roles.map(role => {
                              return (
                                <option key={role.id} value={role.id}>{role.name}</option>
                              )
                            })}
                          </Form.Select> 
                          : user.role.name}
                        </td>
                        <td>
                        <button type="submit" className="btn btn-sm btn-success">Save</button>
                        <button 
                          className="btn btn-sm btn-secondary" 
                          onClick={() => {
                            setCurrentUserId(null);
                            setShowEditMode(false);
                          }}>
                          Cancel
                        </button>
                        </td>
                        <td className="centered">
                        {user.id !== loggedInUser.id ?
                          <i className="bi bi-trash" onClick={(e) => {
                            setCurrentUserId(user.id);
                            setShowConfirmDeleteModal(true);
                          }}/> : null}
                      </td>
                      <td className="centered">
                       {user.id !== loggedInUser.id ? 
                          <i 
                            className="bi bi-envelope-exclamation"
                            title="Send password reset link"
                            onClick={() => {
                              setCurrentUserId(user.id)
                              setShowConfirmSendPwReset(true)
                            }}/>
                        : null}
                      </td>
                    </tr>
                  )
                }
                else {
                  return (
                    <tr key={user.id}>
                      <td>{user.userName}</td>
                      <td>{user.email}</td>
                      <td>{user.role.name}</td>
                      <td className="centered">
                        <i className="bi bi-pencil-square" onClick={() => {
                          setNewuser({userName: user.userName, email: user.email, roleId: user.roleId});
                          setCurrentUserId(user.id);
                          setShowEditMode(true);
                        }}/>
                      </td>
                      <td className="centered">
                        {user.id !== loggedInUser.id ?
                          <i className="bi bi-trash" onClick={(e) => {
                            setCurrentUserId(user.id);
                            setShowConfirmDeleteModal(true);
                          }}/> 
                        : null}
                      </td>
                      <td className="centered">
                        {user.id !== loggedInUser.id ? 
                          <i 
                            className="bi bi-envelope-exclamation"
                            title="Send password reset link"
                            onClick={() => {
                              setCurrentUserId(user.id)
                              setShowConfirmSendPwReset(true)
                            }}/>
                        : null}
                      </td>
                    </tr>
                  )
                }
              })}
            </tbody>
          </table>
        </Form>
        
        <PaginationControls 
          currentPage={currentPage} 
          setCurrentPage={setCurrentPage} 
          totalPages={totalPages}/>
  
        <Modal show={showNewUserModal} onHide={() => setShowNewUserModal(false)}>
          <Modal.Header closeButton>
            <h4 className="modal-title">Create New User</h4>
          </Modal.Header>
          <Form onSubmit={addUser}>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control 
                  type="text" 
                  onChange={(e) => handleUpdateUser("userName", e)}
                  placeholder="Enter username" 
                  required>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                  type="email" 
                  onChange={(e) => handleUpdateUser("email", e)}
                  placeholder="Enter email address" 
                  required>
                </Form.Control>
                <div className="form-text">
                  A link to create a password will be sent to this email address.
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Select defaultValue="0" required onChange={(e) => handleUpdateUser("roleId", e)}>
                  <option disabled value="0">Choose a role</option>
                  {roles.map(role => {
                    return (
                      <option key={role.id} value={role.id}>{role.name}</option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
              <br/>
              <ErrorAlert errorMessage={addUserError}/>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowNewUserModal(false)}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading ? <Loader text="Creating..."/> : "Add"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={showConfirmdeleteModal} onHide={() => setShowConfirmDeleteModal(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Create New User</h4>
        </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this user? Their cases, answers, and case progress will be permanently deleted.</p>
            <p className="text-danger"><b>This action cannot be undone.</b></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirmDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" type="submit" onClick={deleteUser}>Delete permanently</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showConfirmSendPwReset} onHide={() => setShowConfirmSendPwReset(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Send a password reset link</h4>
        </Modal.Header>
          <Modal.Body>
            <p>
              Do you want to send a password reset link to <b>{currentUser ? currentUser.email : ""}</b>?
            </p>
            <p>
              This will allow the user to change their password if they've been locked out or haven't created a password yet.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirmSendPwReset(false)}>
              Cancel
            </Button>
            <Button disabled={isLoading} variant="primary" type="submit" onClick={sendPasswordReset}>
              {isLoading ? <Loader text="Sending..."/> : "Send Link"}
            </Button>
          </Modal.Footer>
        </Modal>

        <ActionSuccess 
          show={showSuccessToast} 
          message={successMessage}
          setShow={setShowSuccessToast}
        />
      </div>
    </div>
  );
}

export default UsersPage;
