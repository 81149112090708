import { Toast } from "react-bootstrap";

interface ActionSuccessProps {
  show: boolean, 
  message: string,
  setShow: (show: boolean) => void
};

function ActionSuccess({ show, message, setShow }: ActionSuccessProps) {

  if (show) {
    return (
      <Toast
        onClose={() => setShow(false)}
        show={show}
        animation={true}
        delay={3000}
        bg={'success'}
        autohide>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
    );
  }

  return null;

};

export default ActionSuccess;
