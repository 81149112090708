interface PaginationControlsProps {
  setCurrentPage: (page: number) => void,
  currentPage: number,
  totalPages: number,
}

// Prev/next controls that display at the bottom of paginated list
export const PaginationControls = ({
  setCurrentPage, 
  currentPage,
  totalPages
}: PaginationControlsProps) => {
  return (
    <div className="user-pagination">
      <button 
        className="btn btn-primary" 
        disabled={currentPage <= 0}
        onClick={(e) => setCurrentPage(currentPage - 1)}>
        <i className="bi bi-arrow-left"/>
      </button>
      <div>
        Page 
        <input 
          type="number" 
          className="form-control" 
          value={currentPage + 1}
          onChange={(e) => {
            const val = parseInt(e.target.value);
            if (val <= totalPages && val > 0) {
              setCurrentPage(val - 1);
            }
          }}/> 
        of {totalPages}
      </div>
      <button 
        className="btn btn-primary" 
        disabled={currentPage + 1 >= totalPages}
        onClick={(e) => setCurrentPage(currentPage + 1)}>
        <i className="bi bi-arrow-right"/>
      </button>
    </div>
  )
}

export default PaginationControls;