


import { useState } from "react";
import { useSelector } from "react-redux";

import PaginationControls from "./paginationControls";
import { User } from "../../types";

interface UserListSearchProps {
  users: Array<User>; 
  addUser: (userId: number) => void;
}

const maxUsersPerPage = 10;

export const UserListSearch = ({users, addUser}: UserListSearchProps) => {

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [keyword, setKeyword] = useState<string>("");
  const loggedInUser: User = useSelector((state: any) => state.user);
  const pageStartIndex = currentPage * maxUsersPerPage;
  const pageEndIndex = (currentPage * maxUsersPerPage) + maxUsersPerPage;
  const filteredUsers = users.filter((u: User) => {
    return (!keyword || u.userName.includes(keyword) || u.email.includes(keyword));
  });
  const totalPages = Math.ceil(filteredUsers.length / maxUsersPerPage);

  return (
    <div>
      <p>Search for an existing user by username or email address: </p>
      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          <i className="bi bi-search"/>
        </span>
        <input 
          type="text"
          className="form-control" 
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Search user"/>
      </div>      
      {users.length > 0 ?
        <div className="instructor-user-list">
          <table className="table table-hover users-table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <td></td>
              </tr>
            </thead>
            <tbody>         
              {filteredUsers.slice(pageStartIndex, pageEndIndex).map((user: User) => {
                if (user.id !== loggedInUser.id) {
                  return (
                    <tr key={user.id}>
                      <td>{user.userName}</td>
                      <td>{user.email}</td>
                      <td>
                        <button 
                          onClick={() => addUser(user.id)} 
                          className="btn btn-primary btn-sm">
                          Add
                        </button>
                      </td>
                    </tr>
                  )
                }
              })}
            </tbody>
          </table>
          <PaginationControls 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            totalPages={totalPages}/>
        </div>
        : <p>No users found.</p>
      }
    </div>
  )
};

export default UserListSearch;