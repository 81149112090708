
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { get } from "superagent";

import { User } from "../../types";
import Login from "./login";

const AuthorizeRedirect = () => {

  const user: User = useSelector((state: any) => state.user);

  const [userValidated, setUserValidated] = useState(false);
  const [isReady, setIsReady] = useState(false);

  // Check if user is already logged in
  const checkForSession = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/session/`)
      .withCredentials()
      .then((res: any) => {
        if (res.body.role) {
          setUserValidated(true);
          setIsReady(true);
        }
      }).catch((err: any) => {
        setIsReady(true);
        console.error(err);
      });
  };

  useEffect(() => {
    if (!user.id) {
      checkForSession();
    }
    else {
      setIsReady(true);
    }
  });


  if (isReady) {
    if (userValidated || user.id) {
      return (
        <div>
          <Navigate to="/account/cases" />
        </div>
      );
    }
  
    // Return login page if not validated
    return (
      <Login />
    );
  }

  else {
    return null;
  }
 

}

export default AuthorizeRedirect;
