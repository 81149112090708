export type User = {
  email: string
  id: number
  roleId: number,
  role: Role,
  userName: string,
  CaseUser: {caseRoleId: number},
  pagestatuses: Array<PageStatus>
}

export type Role = {
  id: number,
  name: string
}

export type Result = {
  responseText: String,
  questionText: String
}

export type Case = {
  name: string;
  createDate: string;
  description: string;
  id: number;
  modifyDate: string;
  pages: Array<any>;
  isPaused: boolean;
  isShareable: boolean;
  documents: Array<Document>;
  users: Array<User>;
  pageStatuses: Array<PageStatus>;
  deletionDate: string
}

export type Page = {
  title: string
  documents: Array<Document>
  feedback: string
  id: number
  time: string
  pageNo: number
  pageText: string|null
  pagestatuses: Array<PageStatus>
  questions: Array<Question>
  isHidden: boolean
  deletionDate: string,
  case: Case
}

export type PageStatus = {
  timeRemain: number
  pageId: number
}

export type Question = {
  answer: string
  choices: Array<Choice>
  correctPageId: number|null
  hasBranch: boolean
  id: number
  incorrectPageId: number|null
  keywords: string
  negativeFeedback: string
  pageId: number
  positiveFeedback: string
  questionText: string
  questionTypeId: number
  tags: string
}

export type Choice = {
  id: number,
  name: string,
  questionId: number
}

export type Response = {
  createDate: string;
  id: number;
  modifyDate: string;
  questionId: number;
  responseText: string;
}

export type StudentCaseData = {
  description: string;
  name: string;
  id: number;
  pageIds: Array<number>
  totalPages: number
  userName: string
}

export type Time = {
  hours: number,
  minutes: number,
  seconds: number
};

export type Answer = {
  text: string,
  correct: boolean
};

export type Answers = {
  [quizId: string]: Answer
}

export type Document = {
  id: number
  title: string
  path: string,
  size: string,
  extension: string,
  page: Page,
  deletionDate: string
 }

 export type DocumentsByPage = {
  [pageId: string]: {
    title: string,
    documents: Array<Document>
  }
 }

 export const roles: {[key: number]: string} = {
  1: "student",
  2: "instructor",
  3: "admin"
 }
