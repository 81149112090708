
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ErrorAlert from "../shared/errorAlert";
import { Case } from "../../types";
import { User } from "../../types";
import { getParticipantCaseStudies } from "../../helpers/api";

function ParticipantCaseList() {

  const [caseStudies, setCaseStudies] = useState<Array<Case>>([]);
  const [ error, setError ] = useState<string>("");
  const user: User = useSelector((state: any) => state.user)

  // Get participating case studies
  const getCaseStudies = async () => {
    const res = await getParticipantCaseStudies();
    res.errrorMsg ? setError(res.errrorMsg) : setCaseStudies(res.data);
  };

  useEffect(() => {
    getCaseStudies();
  }, []);

  return (
    <div>
      {user.roleId !== 1 &&  caseStudies.length > 0 ? 
        <div>
          <br/><hr/><br/>
          <h3>Case studies you're participating in</h3>
        </div> 
      : null}

      {user.roleId === 1 && caseStudies.length === 0 ? 
        <p>
          <br/>
          You are not enrolled in any case studies at this time.
        </p>  
        : null
      }

      {caseStudies.length > 0 ?
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 case-studies-cards">
          {caseStudies.map((cs: Case, i) => {
            const lastPageWithStatus = cs.pages.find(page => page.pagestatuses.length > 0);
            const startingPageId = lastPageWithStatus ? lastPageWithStatus.id : cs.pages[0].id;
            if (cs.pages.length > 0) {
              return (
                <div className="col" key={i}>
                  <div className="card h-100">
                    <div className="card-header">
                      <h2>{cs.name}</h2>
                    </div>
                    <div className="card-body">
                      <p>{cs.description}</p>
                      <a href={`/account/case/${cs.id}/${startingPageId}`}>
                        <button className="btn btn-success case-edit">
                          {lastPageWithStatus ? "Continue" : "Start"}
                          &nbsp;<i className="bi bi-play-fill"/>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </div>
        : null
      }

      <ErrorAlert errorMessage={error}/>
    </div>
  )
}


export default ParticipantCaseList;
