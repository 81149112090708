import { useState } from "react";
import { useParams } from "react-router";
import {  put } from "superagent";

import ErrorAlert from "../shared/errorAlert";
import InfoButtonPopup from "../shared/infoButtonPopup";

interface PageFeedbackProps {
  originalFeedback: string;
  getPage: () => void;
}

const PageFeedback = ({originalFeedback, getPage}: PageFeedbackProps) => {
  const [error, setError] = useState<string>("");
  const [editingFeedback, toggleEditFeedback] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState<string>(originalFeedback);

  let { pageId } = useParams();

  const updateFeedback = async (feedback: string) => {
    if (error) {
      setError("");
    }
    await put(`${process.env.REACT_APP_API_URL}/api/page/feedback/${pageId}`)
      .withCredentials()
      .send({feedback})
      .then(() => getPage())
      .catch((err: any) => {
        setFeedbackValue(originalFeedback);
        if (err.response && err.response.text) {
          setError(err.response.text)
        }
      });
  };

  return (
    <div style={{marginBottom: "40px"}}>

      <h3 className="d-flex">
        Page feedback &nbsp;
        <InfoButtonPopup 
          title="Page Feedback" 
          message="Page feedback will be shown as a popup before the participant proceeds to the next page."/>
      </h3>

      {editingFeedback ? 
        <div className="mb-2">
          <label className="form-label">Edit page feedback:</label><br/>
          <div className="col col-2">
            <input 
              type="text"
              className="form-control mb-2"
              defaultValue={feedbackValue} 
              onChange={(e) => setFeedbackValue(e.target.value)}/>
          </div>
          <button className="btn btn-sm btn-primary" onClick={() => {
            updateFeedback(feedbackValue);
            toggleEditFeedback(false);
          }}>
            <i className="bi bi-pencil-square"></i>&nbsp;&nbsp;Save
          </button>&nbsp;
          <button 
            className="btn btn-sm btn-secondary" 
            onClick={() => {
              toggleEditFeedback(false);
              setFeedbackValue(originalFeedback)}
            }>
            <i className="bi bi-x-circle"/>&nbsp;&nbsp;Cancel
          </button>
        </div> :

        (feedbackValue ? 
          <div>
            <p>
              {feedbackValue} &nbsp;
              <i className="bi bi-pencil-square" onClick={() => toggleEditFeedback(true)}/>
            </p>
            <button 
              className="btn btn-sm btn-danger"
              onClick={() => {
                setFeedbackValue("");
                updateFeedback("");
              }}>
              Remove Feedback
            </button>
          </div>
          : 
          <div>
          <p>You don't have any feedback for this page.</p>
          <button 
            className="btn btn-sm btn-success"
            onClick={() => toggleEditFeedback(true)}>
            Add Feedback +
          </button>
        </div>
        )
      }   

      <ErrorAlert errorMessage={error}/>

    </div>
  );
}

export default PageFeedback;
