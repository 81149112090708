const defaultState = {
  id: null,
  userName: null,
  email: null,
  roleId: null
}

const userReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case 'ADD_USER':
      return {
        id: action.id,
        userName: action.userName,
        email: action.email,
        roleId: action.roleId
      }
    case 'REMOVE_USER':
      return defaultState;
    default:
      return state
  }
}

export default userReducer;