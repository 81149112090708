
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { get, post } from "superagent";
import { socket } from "../../";
import { Modal, Button } from "react-bootstrap";

import AllDocuments from "./all-documents";
import PageDocuments from "./page-documents";
import Questions from "./questions";
import Timer from "./timer";
import { PageStatus, DocumentsByPage, StudentCaseData } from "../../types";

type PageProps = {
  caseData: StudentCaseData;
  pageData: any;
  isPaused: boolean;
  pageIds: Array<number>;
}

const Page = ({ 
  caseData,
  pageData, 
  isPaused, 
  pageIds
}: PageProps) => {


  let { caseId, pageId }: any = useParams();

  const [ timeRemain, setTimeRemain ] = useState<number>(0);
  const [ questionsComplete, toggleQuestionsComplete ] = useState<boolean>(false);
  const [ nextPageId, setNextPageId ] = useState<number|undefined>(pageIds[1] ? pageIds[1] : undefined);
  const [ pageStatuses, setPageStatuses ] = useState<Array<PageStatus>>([]);
  const [ documentsByPage, setDocumentsByPage ] = useState<DocumentsByPage>({});
  const [ showCantProceedMessage, toggleShowCantProceedMessage ] = useState<boolean>(false);
  const [ showPageFeedback, toggleShowPageFeedback ] = useState<boolean>(false);

  const getPageStatuses = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/pagestatus/case/${caseId}`)
    .withCredentials()
    .then((res: any) => { 

      const newPageStatuses = res.body.pageStatuses;

      if (res.body.documentsByPage) {
        setDocumentsByPage(res.body.documentsByPage);
      }

      // If there are previous saved pages
      if (newPageStatuses.length > 0) {

        setPageStatuses(newPageStatuses);
  
        // Set current page ID as last pagestatus saved
        const currentPageStatus = newPageStatuses.find((ps: PageStatus) => ps.pageId == pageId);

        // Set time remain for this page status
        if (currentPageStatus.timeRemain) {
          setTimeRemain(currentPageStatus.timeRemain);
        }

        if (currentPageStatus) {

          const nextPageIndex = pageIds.indexOf(currentPageStatus.pageId) + 1;
          
          // Set next page ID
          if (nextPageIndex) {
            setNextPageId(pageIds[nextPageIndex]);
          }

          else {
            setNextPageId(undefined);
          }

        }

        else {
          setNextPageId(pageIds[1] ? pageIds[1] : undefined);
        }

      }

    })
    .catch((err: any) => { console.log(err) });
  }

  const createPageStatus = async () => {
    if (nextPageId || nextPageId === 0) {

      const nextCurrentPageStatus = pageStatuses.find((ps: any) => ps.pageId === nextPageId);

      if (nextCurrentPageStatus) {
        window.location.replace(`/account/case/${caseId}/${nextPageId}`);
      }

      else {
        await post(`${process.env.REACT_APP_API_URL}/api/pagestatus/${nextPageId}`)
        .withCredentials()
        .then((res: any) => {
          window.location.replace(`/account/case/${caseId}/${nextPageId}`);
        })
        .catch((err: any) => { console.log(err) });
      }

    }
  }

  useEffect(() => {
    // Set page
    socket.emit("set-page", { pageId });
    socket.on("update-time", (data: any) => {
      setTimeRemain(data.timeRemain);
    });
    getPageStatuses();
  }, []);

  const currentPageStatus: any = pageStatuses.find(ps => ps.pageId == pageId);
  const currentPageIndex = pageStatuses.indexOf(currentPageStatus);
  const hasQuestions = pageData.questions && pageData.questions.length > 0;
  const prevPageStatus = pageStatuses[currentPageIndex - 1];
  const pageHasTime = pageData.time !== null;
  const hasUntimedQuestionsLeft = hasQuestions && !questionsComplete && !pageHasTime;
  const hasTimedQuestionsLeft = hasQuestions && !questionsComplete && pageHasTime && timeRemain > 0;

  const handleClickNext = () => {

    // Show can't proceed message if there are questions & they are not complete
    // and time has not run out
    if (hasUntimedQuestionsLeft || hasTimedQuestionsLeft && !isPaused) {
      toggleShowCantProceedMessage(true);
      return
    } 

    // Otherwise, if a next page exists and case study is not paused:
    else if ((nextPageId || nextPageId === 0) && !isPaused) {
  
      // Show page feedback everytime next is clicked
      if (pageData.feedback) {
        toggleShowPageFeedback(true);
      }

      else {
        createPageStatus();
      }

    }

  };

  const handleClickPrev = () => {
    if (!isPaused) {
      window.location.replace(`/account/case/${caseId}/${prevPageStatus.pageId}`)
    }
  }

  if (pageData.isHidden) {
    return (
      <div className="container">
        <div className="hidden-page">
          This page is hidden &nbsp;
          <button onClick={() => {
              window.history.go(-1); 
              return false;
            }} 
            className="btn btn-sm btn-primary">
              Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>

      <Modal show={showCantProceedMessage} onHide={() => toggleShowCantProceedMessage(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Can't proceed</h4>
        </Modal.Header>
        <Modal.Body>
          <p>Please complete all exercises on this page before proceeding.</p>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => toggleShowCantProceedMessage(false)}>Ok</Button>
          </Modal.Footer> 
      </Modal>

      <Modal show={showPageFeedback} onHide={() => {createPageStatus()}}>
        <Modal.Header closeButton><h4 className="modal-title">Message:</h4></Modal.Header>
        <Modal.Body><p>{pageData.feedback}</p></Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => {createPageStatus()}}>Ok</Button>
          </Modal.Footer> 
      </Modal>

      <nav className="navbar" id="nav-secondary">
        <div className="container">
          {prevPageStatus ?
            <a href="#" onClick={handleClickPrev}>
              <button className="btn btn-primary" disabled={isPaused}>
                &laquo; Previous
              </button>
            </a>
          : null }

          <div>Page {currentPageIndex + 1}/{pageStatuses.length}</div>

          {timeRemain && !questionsComplete ? 
            <Timer 
              pageId={pageId} 
              timeRemain={timeRemain} 
              setPreviewTimeRemain={() => null}
              isPaused={isPaused}
            /> 
          : null}

          {Object.keys(documentsByPage).length > 0 ? 
            <AllDocuments documentsByPage={documentsByPage} caseId={caseData.id}/> 
          : null}

          {nextPageId || nextPageId === 0 ?
            <a href="#" onClick={handleClickNext}>
              <button 
                className="btn btn-primary" 
                disabled={hasUntimedQuestionsLeft || hasTimedQuestionsLeft || isPaused}>
                  Next &raquo;
              </button>
            </a>
          : null}
          
        </div>
      </nav>

      <main role="main">
        <div className="container">

          <h1>{pageData.title}</h1>

          {pageData.pageText ? parse(pageData.pageText) : null}

          { pageData.documents && pageData.documents.length > 0 ? 
            <PageDocuments documents={pageData.documents} caseId={caseId}/> 
          : null }

          { pageData.questions && pageData.questions.length > 0 ? 
            <Questions 
              questions={pageData.questions} 
              pageId={pageData.id} 
              isPaused={isPaused}
              timeIsUp={pageHasTime ? timeRemain === 0 : false}
              isLastPage={pageIds.length === pageStatuses.length ? true : false}
              handleSetQuestionsComplete={toggleQuestionsComplete}
              handleSetNextPageId={setNextPageId}
              /> 
          : null }

        </div>
      </main>
    </div>
  );
  

 
}

export default Page;
