import { useState } from "react";
import { useSelector } from "react-redux";
import { User } from "../../types";
import Header from "./header";
import { roles } from "../../types";

function ProfilePage() {

  const user: User = useSelector((state: any) => state.user)

  return (
    <div>
      <Header title="Case Study Builder" isPaused={false}/>
      
      <div className="container">
        <h2>User Profile</h2>
        <br/>
        <p>
          <b>Username: </b> {user.userName}
          <br/>
          <b>Email: </b> {user.email}
          <br/>
          <b>Role: </b> {roles[user.roleId]}
        </p>
        <br/>
        <a href="/account/profile/change-password">
          <button className="btn btn-primary">Change password</button>
        </a>
      </div>

    </div>
  );
}

export default ProfilePage;
