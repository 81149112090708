import { useState } from "react";
import { Modal, Button, Form, InputGroup, Toast } from "react-bootstrap";
import { useParams } from "react-router";
import { Alert } from "react-bootstrap";

import { Page, Question, Choice } from "../../types";

type QuestionProps = {
  pages: Array<Page>,
  handleAddQuestion: (q: Question, opts: Array<string>) => any,
  handleDeleteQuestion: (id: number) => any,
  handleUpdateQuestion: (q: Question, opts: Array<string>) => any,
  questions: Array<Question>
}

const Questions = ({ 
  pages, 
  handleAddQuestion, 
  handleDeleteQuestion,
  handleUpdateQuestion,
  questions 
}: QuestionProps) => {

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showPopupAlert, setShowPopupAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [popupAlertMessage, setPopupAlertMessage] = useState<string>("");
  const [showAddQuestionModal, toggleAddQuestionModal] = useState(false);
  const [showConfirmDelete, toggleShowConfirmDelete] = useState(false);
  const [showBranchOptions, toggleShowBranchOptions] = useState(false);
  const [questionToDeleteId, setQuestionToDeleteId] = useState<number>(-1);
  const [showToast, toggleShowToast] = useState(false);
  const [showMultipleChoiceOptionElement, setShowMultipleChoiceOptionElement] = useState(false);
  const [multipleChoiceOption, setMultipleChoiceOption] = useState<string>("")
  const [multipleChoiceOptions, setMultipleChoiceOptions] = useState<Array<string>>([]);
  const [action, setAction] = useState("");

  let { pageId } = useParams();

  const [newQuestionValues, setNewQuestionValues] = useState<Question>({
    id: 0,
    pageId: pageId ? parseInt(pageId) : 0,
    questionTypeId: 0,
    questionText: "",
    answer: "",
    choices: [],
    keywords: "",
    tags: "",
    negativeFeedback: "",
    positiveFeedback: "",
    hasBranch: false,
    correctPageId: null,
    incorrectPageId: null
  });

  const setActiveQuestion = async (question: any) => {
    setNewQuestionValues((values: Question) => ({
      ...values,
      id: question.id,
      pageId: question.pageId,
      questionTypeId: question.questionTypeId,
      questionText: question.questionText,
      answer: question.answer,
      choices: question.choices,
      keywords: question.keywords,
      tags: question.tags,
      negativeFeedback: question.negativeFeedback,
      positiveFeedback: question.positiveFeedback,
      hasBranch: question.hasBranch,
      correctPageId: question.correctPageId,
      incorrectPageId: question.incorrectPageId
    }))

    if (question.choices) {
      setMultipleChoiceOptions(() => {
        return question.choices.map((c: Choice) => c.name);
      });
    }

  }

  const saveQuestion = async (e: any) => {
    e.preventDefault();

    if (action === "Add") {
      addQuestion();
    } else {
      editQuestion();
    }
    return;
  }

  const addQuestion = async () => {

    const error = await handleAddQuestion(newQuestionValues, multipleChoiceOptions);

    if (error) {
      setShowAlert(true);
      setAlertMessage(error.response.text ? error.response.text : "An error occured. Question was not added.");
      console.error(error);
    }

    toggleAddQuestionModal(false);

  }

  const editQuestion = async () => {
    const error = await handleUpdateQuestion(newQuestionValues, multipleChoiceOptions);

    if (!error) {
      toggleShowToast(true);
    }

    else {
      setShowAlert(true);
      setAlertMessage(error.response.text ? error.response.text : "An error occured. Question was not updated.");
      console.error(error);
    }

    toggleAddQuestionModal(false);
  }

  const deleteQuestion = async () => {
    if (questionToDeleteId >= 0) {

      toggleShowConfirmDelete(false);
      const error =  await handleDeleteQuestion(questionToDeleteId);

      if (error) {
        setShowAlert(true);
        setAlertMessage(error.response.text ? error.response.text : "An error occured. Question was not deleted.");
        console.error(error);
      }
    }
  }

  const deleteOption = async (arrayId: number) => {
    // Remove option from array
    const optionsCopy = [...multipleChoiceOptions];
    optionsCopy.splice(arrayId, 1);
    setMultipleChoiceOptions(optionsCopy);
  }

  function addMultipleChoiceOption(e: any) {
    e.preventDefault();
    setMultipleChoiceOptions([...multipleChoiceOptions, multipleChoiceOption]);
    setShowMultipleChoiceOptionElement(false);
  }

  function isChecked(question: any, type: string) {
    let retVal = false;
    if (question.questionTypeId === 1 && type === "text") {
      retVal = true;
    } else if (question.questionTypeId === 2 && type === "multiplechoice") {
      retVal = true;
    }
    return retVal;
  }

  return (
    <div>

      <div style={{ position: "absolute", bottom: "40px", left: "40px", color: "white" }}>
        <Toast
          onClose={() => toggleShowToast(false)}
          show={showToast}
          animation={true}
          delay={3000}
          bg={'success'}
          autohide>
          <Toast.Body>Question created successfully!</Toast.Body>
        </Toast>
      </div>

      <Modal show={showConfirmDelete} onHide={() => toggleShowConfirmDelete(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Delete Answer</h4>
        </Modal.Header>
        <Modal.Body>
          Deleting this question will also delete responses to it, are you sure you want to proceed?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => toggleShowConfirmDelete(false)}>Cancel</Button>
          <Button variant="btn btn-danger" onClick={() => deleteQuestion()}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <div className="row align-items-center">

        <div>
          <hr className="my-4" />

          <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
            <Alert.Heading>Error</Alert.Heading>
            <p>{alertMessage}</p>
          </Alert>

          <p className="float-end">
            <button type="button" className="btn btn-primary mt-2" onClick={() => { setNewQuestionValues({
              id: 0,
              pageId: pageId ? parseInt(pageId) : 0,
              questionTypeId: 0,
              questionText: "",
              answer: "",
              choices: [],
              keywords: "",
              tags: "",
              negativeFeedback: "",
              positiveFeedback: "",
              hasBranch: false,
              correctPageId: null,
              incorrectPageId: null
            }); setMultipleChoiceOptions([]); setAction("Add"); toggleAddQuestionModal(true); }}>Add Question</button>
          </p>

          <h3 className="mb-4">Questions</h3>

          {questions.map((question, i) => {
            const correctPage = pages.find(p => p.id === question.correctPageId);
            const incorrectPage = pages.find(p => p.id === question.incorrectPageId);

            return <div className="question-details" key={question.id}>
              <p className="float-end">
                <button 
                className="btn btn-sm btn-primary" 
                onClick={() => { 
                  setMultipleChoiceOptions([]); 
                  setActiveQuestion(question); 
                  setAction("Edit"); 
                  toggleAddQuestionModal(true); 
                }}>
                  Edit
                </button>
                &nbsp;
                <button 
                  className="btn btn-sm btn-danger" 
                  onClick={() => {
                    setQuestionToDeleteId(question.id);
                    toggleShowConfirmDelete(true);
                  }}>
                  Delete
                </button>
              </p>
              <h4>Question {i + 1}</h4>
              <table className="table table-striped question-table"> 
                <tbody>
                  <tr>
                    <th style={{"width": "132px"}}>Question text</th>
                    <td>{question.questionText}</td>
                  </tr>
                  <tr>
                    <th style={{"width": "132px"}}>Question type</th>
                    <td><span>{question.questionTypeId === 1 ? "Text" : "Multiple Choice"}</span></td>
                  </tr>

                  {question.questionTypeId === 1 ?  // Text question type
                    <tr>
                      <th style={{"width": "132px"}}>Keywords</th>
                      <td>{question.keywords}</td>
                    </tr>
                    :                               // Multiple choice question type
                    <tr>
                      <th style={{"width": "132px"}}>Options</th>
                      <td>
                        <div>
                        {
                          question.choices.map((choice: Choice, i) => {
                            return (
                              <span key={i}>
                                <button className='btn btn-sm btn-secondary'>{choice.name}</button>&nbsp;
                              </span>
                            );
                          })       
                        }
                        </div>
                      </td>
                    </tr>
                  }

                  <tr>
                    <th style={{"width": "132px"}}>Correct answer</th>
                    <td>{question.answer}</td>
                  </tr>
                  <tr>
                    <th style={{"width": "132px"}}>Tags</th>
                    <td>{question.tags} </td>
                  </tr>
                  <tr>
                    <th style={{"width": "132px"}}>Feedback</th>
                    <td>
                      <p><strong>Correct:</strong> {question.positiveFeedback}</p>
                      <p><strong>Incorrect:</strong> {question.negativeFeedback}</p>
                    </td>
                  </tr>
                  <tr>
                    <th style={{"width": "132px"}}>Branching</th>
                    <td>
                      {question.hasBranch ?
                        <div>
                          <p><strong>Correct page:</strong> {pages.find(p => p.id === question.correctPageId)?.pageNo} - {correctPage ? (correctPage.title ? `"${correctPage.title}"` : "Untitled") : "None"}</p>
                          <p><strong>Incorrect page:</strong> {pages.find(p => p.id === question.incorrectPageId)?.pageNo} - {incorrectPage ? (incorrectPage.title ? `"${incorrectPage.title}"` : "Untitled") : "None"}</p>
                        </div>
                        :
                        <div>
                          None
                        </div>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          })}


        </div >
      </div >

      {/* *************************** Add/Edit Question Modal *************************** */}

      <Modal 
        size="lg" 
        show={showAddQuestionModal} 
        onHide={() => {
          toggleAddQuestionModal(false);
          if (showPopupAlert) {
            setShowPopupAlert(false);
          }
          if (showBranchOptions) {
            toggleShowBranchOptions(false);
          }
        }}
        backdrop="static">

      <Modal.Header closeButton>
        <h4 className="modal-title">{action} Question</h4>
      </Modal.Header>
      <Modal.Body>

      <Form onSubmit={(e) => saveQuestion(e)}>
        
        <div className="mb-3">
          <Form.Group controlId="questionText">
            <Form.Label>Question text</Form.Label>
              <InputGroup hasValidation>
                <Form.Control 
                  as="textarea" 
                  rows={1}
                  autoFocus
                  placeholder="Question text"
                  aria-describedby="inputGroupPrepend"
                  value={newQuestionValues.questionText}
                  onChange={(e) => setNewQuestionValues((values) => ({
                    ...values,
                    questionText: e.target.value
                  }))}

                  required
                />
              </InputGroup>
            </Form.Group>
        </div>
        
        <div className="mb-3">
          <Form.Group controlId="questionType">
              <InputGroup hasValidation>
              <span><b>Question type</b>&nbsp;&nbsp;</span> <Form.Check 
                  name="questiontype"
                  value="1"
                  type="radio" 
                  label="Text"
                  checked={isChecked(newQuestionValues, "text")}
                  disabled = {action === "Add" ? false : true }
                  onChange={(e) => setNewQuestionValues((values) => ({
                    ...values,
                    questionTypeId: parseInt(e.target.value)
                  }))}
                  required
                />&nbsp;&nbsp;
                <Form.Check 
                  name="questiontype"
                  value="2"
                  type="radio" 
                  label="Multiple choice"
                  checked={isChecked(newQuestionValues, "multiplechoice")}
                  disabled = {action === "Add" ? false : true }
                  onChange={(e) => setNewQuestionValues((values) => ({
                    ...values,
                    questionTypeId: parseInt(e.target.value)
                  }))}
                  required
                />      
              </InputGroup>
            </Form.Group>
          </div>
          <div className="mb-3">
          
          {newQuestionValues.questionTypeId === 1  ? 
          <Form.Group controlId="keywords">
            <Form.Label>Keywords</Form.Label>
              <InputGroup hasValidation>
                <Form.Control 
                  type="text" 
                  placeholder="Comma-separated keywords"
                  aria-describedby="inputGroupPrepend"
                  value={newQuestionValues.keywords}
                  onChange={(e) => setNewQuestionValues((values) => ({
                    ...values,
                    keywords: e.target.value
                  }))}
                />
            </InputGroup>
          </Form.Group> : null}

          </div>
          
          {/* Show option section only if multiple choice question */}
          {newQuestionValues.questionTypeId === 2 ?
            <div className="question-details">
              <p className="float-end">
                <button className="btn btn-primary btn-sm" onClick={(e) => { e.preventDefault();  setShowMultipleChoiceOptionElement(true); }}>
                  Add Option
                </button>
              </p>
              {showMultipleChoiceOptionElement ? 
                <div>
                  <Form.Group id="formOption">
                    <div className="mb-3">
                      <Form.Group controlId="optionText">
                        <Form.Label>Multiple Choice Option</Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control 
                              type="text" 
                              autoFocus
                              placeholder="Option value"
                              aria-describedby="inputGroupPrepend"
                              onChange={(e) => setMultipleChoiceOption(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="float-end">
                      <Button 
                        variant="btn btn-sm btn-primary" 
                        type="submit" 
                        onClick={(e: any) => { addMultipleChoiceOption(e) }}>
                        Add
                      </Button>&nbsp;
                      <Button 
                        variant="btn btn-sm btn-secondary" 
                        onClick={() => setShowMultipleChoiceOptionElement(false)}>
                          Cancel
                      </Button>
                    </div>
                  </Form.Group>
                </div>
              : null}
              
              <br/>

              <label className="form-label">Options</label> 
              <i style={{fontSize: "small", color: "grey"}}>&nbsp;(Click to remove)</i>
              <br/>

              {multipleChoiceOptions ? multipleChoiceOptions.map((name: string, i) => {
                  return (
                  <span key={i} id={"option" + i}>
                    <Button variant="outline-danger" size="sm" onClick={(e: any) => {
                        e.preventDefault(); 
                        deleteOption(i)
                      }}>
                      {name}
                    </Button>
                    &nbsp;
                  </span>);
                }) : null}
            </div>
          : null} 


        {newQuestionValues.questionTypeId === 2 ? 
          <div className="mb-3">
            <Form.Group controlId="answer">
                <Form.Label>Correct answer</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control 
                      type="text" 
                      placeholder="Correct answer"
                      aria-describedby="inputGroupPrepend"
                      value={newQuestionValues.answer}
                      onChange={(e) => setNewQuestionValues((values) => ({
                        ...values,
                        answer: e.target.value
                      }))}
                    />
                  </InputGroup>
              </Form.Group>
          </div> : null }

          <div className="mb-3">
            <Form.Group controlId="correctFeedback">
              <Form.Label>Correct feedback</Form.Label>
              <InputGroup hasValidation>
                <Form.Control 
                  as="textarea" 
                  rows={1}
                  placeholder="Correct feedback"
                  aria-describedby="inputGroupPrepend"
                  value={newQuestionValues.positiveFeedback}
                  onChange={(e) => setNewQuestionValues((values) => ({
                    ...values,
                    positiveFeedback: e.target.value
                  }))}
                />
              </InputGroup>
            </Form.Group>
          </div>

          <div className="mb-3">
            <Form.Group controlId="incorrectFeedback">
              <Form.Label>Incorrect feedback</Form.Label>
              <InputGroup hasValidation>
                <Form.Control 
                  as="textarea" 
                  rows={1}
                  placeholder="Incorrect feedback"
                  aria-describedby="inputGroupPrepend"
                  value={newQuestionValues.negativeFeedback}
                  onChange={(e) => setNewQuestionValues((values) => ({
                    ...values,
                    negativeFeedback: e.target.value
                  }))}
                />
              </InputGroup>
            </Form.Group>
          </div>

          <div className="mb-3">
            <Form.Group controlId="branching">
              <InputGroup hasValidation>
                <Form.Check 
                  aria-label="Branched?"
                  checked={newQuestionValues.hasBranch}
                  onChange={(e: any) => {
                    const questionAlreadyBranched = questions.find((q: Question) => q.hasBranch);

                    // Only allow 1 branched question per page
                    if (e.target.checked && questionAlreadyBranched) {
                      const notBranchedQuestion = questionAlreadyBranched.id !== newQuestionValues.id;
                        if (notBranchedQuestion) {
                        setShowPopupAlert(true);
                        setPopupAlertMessage("A question on this page is already branched. Only one question per page can trigger branching.");
                        return;
                      }
                    }

                    toggleShowBranchOptions(e.target.checked);
                    setNewQuestionValues((values) => ({
                    ...values,
                    hasBranch: e.target.checked,
                    correctQuestionId: null,
                    incorrectQuestionId: null
                  }))}}
                />  <span> &nbsp;&nbsp;<b>Branched?</b></span>
              </InputGroup>
            </Form.Group>
            
            {/* Branch Options */}
            {showBranchOptions || newQuestionValues.hasBranch ? 
              <div>
                <label htmlFor="branchCorrectAnswer" className="form-label">Correct answer</label>
                <select className="form-select" aria-label="Correct answer" id="branchCorrectAnswer"
                  defaultValue={newQuestionValues.correctPageId ? newQuestionValues.correctPageId : "Select the page to go to after a correct answer"}
                  onChange={(e) => setNewQuestionValues((values: any) => ({
                    ...values,
                    correctPageId: parseInt(e.target.value)
                  }))}>

                  <option value={""}>Select the page to go to after a correct answer</option>

                  {pages.map((page) => {
                    return (
                      <option key={page.id} value={page.id}>
                        Page {page.pageNo} - {page.title ? `"${page.title}"` : "Untitled"}
                      </option>
                    )
                  })}            
                </select>

                <label htmlFor="branchIncorrectAnswer" className="form-label">Incorrect answer</label>
                <select className="form-select" aria-label="Correct answer" id="branchIncorrectAnswer"
                  defaultValue={newQuestionValues.incorrectPageId ? newQuestionValues.incorrectPageId : "Select the page to go to after a correct answer"}
                  onChange={(e) => setNewQuestionValues((values: any) => ({
                    ...values,
                    incorrectPageId: parseInt(e.target.value)
                  }))}>

                  <option value={""}>Select the page to go to after a correct answer</option>

                  {pages.map((page, i) => {
                    return <option key={i} value={page.id}>Page {page.id} - {page.title ? page.title : "Untitled"}</option>
                  })}  
                </select>
              </div>
            : null }

          </div>

          <div className="mb-3">
            <Form.Group controlId="tags">
              <Form.Label>Tags</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control 
                    type="text" 
                    placeholder="Comma-separated tags"
                    aria-describedby="inputGroupPrepend"
                    value={newQuestionValues.tags}
                    onChange={(e) => setNewQuestionValues((values) => ({
                      ...values,
                      tags: e.target.value
                    }))}
                  />
                </InputGroup>
            </Form.Group>
          </div>

            <Alert 
              variant="danger" 
              show={showPopupAlert} 
              onClose={() => setShowPopupAlert(false)} 
              dismissible>
              <p>{popupAlertMessage}</p>
            </Alert>
          <Modal.Footer>
            <Button variant="primary" type="submit">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
      </Modal>
    </div >
  );
}

export default Questions;
