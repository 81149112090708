import { useEffect, useState } from "react";
import { Accordion  } from "react-bootstrap";
import parse from 'html-react-parser';

import { Case, Question, Document } from "../../types";

interface SharedCasePreviewProps {
  sharedCase: Case,
  setSelectedCase: (arg: any) => void
};

export const SharedCasePreview = ({sharedCase, setSelectedCase}: SharedCasePreviewProps) => {

  if (sharedCase) {
    return (
      <div>
        <a href="#" onClick={() => setSelectedCase(null)}>&laquo; Back to list</a>
        <br/><br/>
        <h3>{sharedCase.name}</h3>
        <p><b>Description: </b>{sharedCase.description ? sharedCase.description : "None"}</p>
        <br/>
        <h4>Pages</h4>

        {sharedCase.pages.length === 0 ? "No pages" : null}

        <Accordion defaultActiveKey="0">
          {sharedCase.pages.map((page, i) => {
            return (
              <Accordion.Item key={i} eventKey={page.id}>
                <Accordion.Header>{page.title}</Accordion.Header>
                <Accordion.Body>
                  
                  {page.time ? 
                    <div>
                      <p><b>Time limit: </b> {page.time} minutes</p>
                    </div>
                  : null}

                  {page.pageText ? 
                    <div>
                      <h4>Page Text</h4>
                      <div className="page-text-preview">
                        {page.pageText ? parse(page.pageText) : null}
                      </div> 
                    </div>
                  : null }

                  {page.documents.length > 0 ? 
                    <div>
                      <h4>Documents:</h4>
                      <ul>
                      {page.documents.map((d: Document) => <li key={d.id}>{d.title}</li>)}
                      </ul>
                    </div>
                  : null}

                  {page.questions.length > 0 ? 
                    <div>
                      <h4>Questions:</h4>
                      <ul>
                        {page.questions.map((q: Question) => <li key={q.id}>{q.questionText}</li>)}
                      </ul>
                    </div>
                  : null}

                </Accordion.Body>
              </Accordion.Item>
            );
          })}
          
        </Accordion>

      </div>
    )
  }

  return null;

};

export default SharedCasePreview;

