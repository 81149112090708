import update from 'immutability-helper'
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { Button, Modal, Toast, Alert } from "react-bootstrap";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { del, get, post, patch } from "superagent";

import Card from "./card";
import { Page } from "../../../types";
import ErrorAlert from "../../shared/errorAlert";

export interface NewPage {
  order: number;
  id: string;
  title: string;
}

interface PageOrder { 
  id: string,
  pageNo: number
}

const style = {
  width: 400,
}

export interface Item {
  id: number
  text: string
}

export interface ContainerState {
  cards: Item[]
}

export const PageDragAndDrop = ()  => {
  const [selectedPageId, setSelectedPageId] = useState<number|null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  let { caseId } = useParams();
  const [ pages, setPages ] = useState<Array<Page>>([]);

  useEffect(() => {
    getPages();
  }, []);

  const handleError = (err: any) => {
    if (err.response && err.response.text) {
      setError(err.response.text)
    }
  }

  // Updates order
  const movePage = useCallback((dragIndex: number, hoverIndex: number) => {
    setPages((prevPages: Page[]) =>
      update(prevPages, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevPages[dragIndex] as Page],
        ],
      }),
    )
  }, []);

  // Updated pages after drop
  const dropPage = async() => {
    const newPageOrder = pages.map((page, i) => {
      return ({
        pageNo: i,
        id: page.id,
        isHidden: page.isHidden
      });
    });
    await patch(`${process.env.REACT_APP_API_URL}/api/page/order/`)
    .withCredentials()
    .send(newPageOrder)
    .catch((err: any) => { handleError(err) });
    getPages();
  }

  const getPages = async() => {
    await get(`${process.env.REACT_APP_API_URL}/api/page/case/${caseId}`)
    .withCredentials()
    .then((res: any) => { setPages(res.body.pages) })
    .catch((err: any) => { handleError(err) });
  }

  const hidePage = async(pageId: number, isHidden: boolean) => {
    await patch(`${process.env.REACT_APP_API_URL}/api/page/hide/${pageId}`)
    .send({isHidden})
    .withCredentials()
    .then((res: any) => { getPages() })
    .catch((err: any) => { handleError(err) });
  }

  // Add new page
  const addPage = async () => {
    await post(`${process.env.REACT_APP_API_URL}/api/page/${caseId}`)
      .withCredentials()
      .send({pageNo: pages.length + 1})
      .then((res: any) => { getPages() }) // Refresh pages
      .catch((err: any) => { handleError(err) });
  }
  
  const deletePage = async (pageId: number|null) => {
    await del(`${process.env.REACT_APP_API_URL}/api/page/${pageId}?caseId=${caseId}`)
      .withCredentials()
      .then((res: any) => {
        getPages();
        setShowToast(true);
      }).catch((err: any) => { handleError(err) });
  }

  return (
    <div className="col">

      <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Delete Page</h4>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this page?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowConfirmDelete(false)}>Cancel</Button>
          <Button variant="btn btn-danger" onClick={() => {
            deletePage(selectedPageId);
            setShowConfirmDelete(false);
            }}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <div style={{ position: "absolute", bottom: "40px", left: "40px", color: "white" }}>
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          animation={true}
          delay={3000}
          bg={'success'}
          autohide>
          <Toast.Body>Page deleted!</Toast.Body>
        </Toast>
      </div>

      <p className="float-end">
       <Button variant="primary" className="mt-2" onClick={() => addPage()}>Add page + </Button>
      </p>

      <h3>Pages</h3>

      <div className="clearfix"></div>

      {pages.length === 0 ? <p>You don't have any pages yet.</p> : <p>Drag to reorder pages</p>}

      <DndProvider backend={HTML5Backend}>
        <ul className="list-group">
          {pages.map((page, i) => { 
            const firstHiddenPage = pages.find(p => p.isHidden);
            const firstHiddenPageIndex = firstHiddenPage ? pages.indexOf(firstHiddenPage) : -1;
            const isLastPage = i === (pages.length - 1);
            console.log("IS HIDDEN", page.isHidden)
            return <Card
              key={page.id}
              index={i}
              id={page.id}
              isHidden={page.isHidden}
              dropPage={dropPage}
              movePage={movePage}> 

              <div 
                style={{cursor: page.isHidden ? "auto" : "move"}} 
                className={`${page.isHidden ? "page-list-draggable-hidden" : ""} page-list-draggable`}>
                <div className="list-group-item d-flex justify-content-between align-items-center">
                  <div className="page-title">
                    {page.title ? page.title : "New Page"}
                    {page.isHidden ? <i> - hidden</i> : null}
                  </div>
                  <div>

                    {/* Only pages at the end, or with a hidden page after can be hidden */}
                    {isLastPage || (i === firstHiddenPageIndex - 1) || page.isHidden ?
                      <button 
                        className="btn btn-sm btn-primary"
                        data-toggle="tooltip" 
                        data-placement="top" 
                        title="Hide Page"
                        disabled={i !== firstHiddenPageIndex && page.isHidden}
                        onClick={() => hidePage(page.id, !page.isHidden)}>
                        {page.isHidden ? <i className="bi bi-eye-slash"/> : <i className="bi bi-eye"/>}
                      </button>  
                    : null }

                    &nbsp;
                    <a href={`/account/case/${caseId}/${page.id}`}>
                      <button className="btn btn-sm btn-primary">
                        <i className="bi bi-pencil-square"></i>&nbsp;&nbsp;Edit
                      </button>
                    </a>
                    &nbsp;
                    <button 
                      className="btn btn-sm btn-danger" 
                      onClick={() => {
                        setSelectedPageId(page.id);
                        setShowConfirmDelete(true)}
                      }>
                      <i className="bi bi-x-circle-fill"></i>&nbsp;&nbsp;Delete
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          })}

        </ul>

      </DndProvider>

      <br/>
      
      <ErrorAlert errorMessage={error}/>

    </div>

  );

}

export default PageDragAndDrop;
