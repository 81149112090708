import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Alert } from "react-bootstrap";
import {  put } from "superagent";

import ErrorAlert from "../shared/errorAlert";
import InfoButtonPopup from "../shared/infoButtonPopup";

interface TimerProps {
  originalTime: string;
  getPage: () => void;
}

const Timer = ({originalTime, getPage}: TimerProps) => {
  const [error, setError] = useState<string>("");
  const [editingTime, toggleEditTime] = useState(false);
  const [timeValue, setTimeValue] = useState<string>(originalTime);

  let { pageId } = useParams();

  const updateTime = async (time: string) => {
    if (error) {
      setError("");
    }
    await put(`${process.env.REACT_APP_API_URL}/api/page/time/${pageId}`)
      .withCredentials()
      .send({time})
      .then(() => getPage())
      .catch((err: any) => {
        setTimeValue(originalTime);
        if (err.response && err.response.text) {
          setError(err.response.text)
        }
      });
  };

  return (
    <div>

        <h3 className="d-flex">
          Timer &nbsp;
          <InfoButtonPopup 
            title="Page Timers" 
            message="By adding a timer to a page, the participant will only be allowed to answer questions within the set time limit. They may proceed to the next page when the time limit is up, even if questions aren't answered."/>
        </h3>
        
        {editingTime ? 
          <div className="mb-2">
            <label className="form-label">Edit timer length: (in minutes):</label><br/>
            <div className="col col-2">
              <input 
                type="number"
                min="0"
                className="form-control mb-2"
                defaultValue={timeValue} 
                onChange={(e) => setTimeValue(e.target.value)}/>
            </div>
            <button className="btn btn-sm btn-primary" onClick={() => {
              updateTime(timeValue);
              toggleEditTime(false);
            }}>
              <i className="bi bi-pencil-square"></i>&nbsp;&nbsp;Save
            </button>&nbsp;
            <button 
              className="btn btn-sm btn-secondary" 
              onClick={() => {
                toggleEditTime(false);
                setTimeValue(originalTime)}
              }>
              <i className="bi bi-x-circle"/>&nbsp;&nbsp;Cancel
            </button>
          </div> :

          (timeValue && timeValue !== "0" ? 
            <div>
              <p>
                {timeValue} minutes &nbsp;
                <i className="bi bi-pencil-square" onClick={() => toggleEditTime(true)}/>
              </p>
              <button 
                className="btn btn-sm btn-danger"
                onClick={() => {
                  setTimeValue("0");
                  updateTime("0");
                }}>
                Remove Timer
              </button>
            </div>
            : 
            <div>
            <p>You don't have a time limit set on this page.</p>
            <button 
              className="btn btn-sm btn-success"
              onClick={() => toggleEditTime(true)}>
              Add Timer +
            </button>
          </div>
          )
        }   

        <ErrorAlert errorMessage={error}/>

      <hr className="my-4" />

    </div>
  );
}

export default Timer;
