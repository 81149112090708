
import { useEffect, useState } from "react";
import { Form, Row, InputGroup, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { post } from "superagent";

import Header from "./header";
import Loader from "./loader";
import ErrorAlert from "../shared/errorAlert";
import { User } from "../../types";

function ForgotPassword() {

  const user: User = useSelector((state: any) => state.user)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const sendLink = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("username", userName)
    await post(`${process.env.REACT_APP_API_URL}/api/email/token/${userName}`)
      .withCredentials()
      .then((res: any) => {
        if (res.body.success) {
          setSuccess(true);
        }
        setIsLoading(false);
      }).catch(err => {
        if (err.response && err.response.text) {
          console.log(err);
          setError(err.response.text);
        }
        else {
          setError("An issue occured, email not sent");
        }
        setIsLoading(false);
      })
  };

  return (
    <div>
    
      <Header title="Log into Case Study" isPaused={false}/>

      <main role="main">

        {success ? 
          <div className="container">
            Email has been sent, please check your inbox.
            <br/><br/>
            <a href="/"><i className="bi bi-arrow-left"/> Back to homepage</a>
          </div> :
          <div className="container">
            <div className="col-5">
              <br/><br/>
              <br/>
              <p>Enter your username below. A password reset link will be sent to the email address associated with your username.</p>
              <div className="row justify-content-md-center change-password-form-container">
                  
                <Form onSubmit={sendLink}>
                  <Row className="mb-3">
                    <Form.Group controlId="validationPassword">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Username"
                          onChange={(e) => setUserName(e.target.value)}
                          required/>
                      </InputGroup>
                    </Form.Group>
                  </Row>

                  <ErrorAlert errorMessage={error}/>

                  <Button type="submit" onClick={sendLink} disabled={!userName || isLoading}>
                    {isLoading ? <Loader text="Sending..."/> : "Send Link"}
                  </Button>
                </Form>

              </div>
            </div>
          </div>
        }

      </main>

    </div>
  );

}

export default ForgotPassword;
