import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Row, InputGroup, Button } from "react-bootstrap";
import { get, post, del } from "superagent";

import { User } from "../../types";
import ErrorAlert from "../shared/errorAlert";
import Loader from "../shared/loader";
import ActionSuccess from "../shared/actionSuccess";
import UserListSearch from "../shared/user-list-search";

type EmailLoadingStatus = {[userId: string]: boolean};
interface NewUserValues {
  userName: string,
  email: string
};

const blankUser = {
  userName: "",
  email: ""
}

export const NewUser = ({
  createNewUser, 
  setIsAddingNew, 
  setNewUserValues, 
  isLoading
}: any) => {
  return (
    <Form onSubmit={(e) => createNewUser(e)}>
      <a href="#" onClick={() => setIsAddingNew(false)}>
        <i className="bi bi-arrow-left-short"/> Add existing user
      </a>
      <br/><br/>
      <p>Create a new user that will be added as a participant in this case study.</p>
      <Row className="mb-3">
        <Form.Group controlId="validationNewCase">
          <Form.Label>Username:</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              required
              onChange={(e) => setNewUserValues((values: any) => (
                {
                ...values,
                userName: e.target.value,
              }))}
            />
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <br/>
          <Form.Label>Email address</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="email"
              placeholder="Enter email"
              required
              onChange={(e) => setNewUserValues((values: any) => ({
                ...values,
                email: e.target.value,
              }))}
            />
          </InputGroup>
          <div className="form-text">
            A link to create a password will be sent to this email address.
          </div>
          <br/>
        </Form.Group>

        <Modal.Footer>
          <Button disabled={isLoading} variant="primary" type="submit">
            {isLoading ? <Loader text="Creating..."/> : "Create"}
          </Button>
        </Modal.Footer>

      </Row>
    </Form>
  )
}

interface UsersProps {
  caseUsers: Array<User>,
  getCaseUsers: any,
  caseId: number
}

export const Users = ({caseUsers, getCaseUsers, caseId}: UsersProps) =>{

  const loggedInUser: User = useSelector((state: any) => state.user);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number|null>(null);
  const [users, setUsers] = useState<Array<User>>([]);
  const [isAddingNew, setIsAddingNew] = useState<boolean>(false);
  const [showAddUserModal, toggleShowAddUserModal] = useState<boolean>(false);
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState<boolean>(false);
  const [newUserValues, setNewUserValues] = useState<NewUserValues>(blankUser);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const selectedUser = selectedUserId ? caseUsers.find(u => u.id === selectedUserId) : null;
  const nonParticipantUsers = users.filter((u: User) => {
    return caseUsers.find((cu: User) => cu.id !== u.id);
  });

  // Get all users
  const getUsers = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/user/`)
    .withCredentials()
    .then((res: any) => { 
      setUsers(res.body.filter((u: User) => {
        return caseUsers.find((cu: User) => cu.id === u.id) === undefined;
      }));
    }).catch((err: any) => {
      if (err.response && err.response.text) {
        setError(err.response.text)
      }
    });
  }

  const addExistingUser = async (userId: number) => {
    await post(`${process.env.REACT_APP_API_URL}/api/user/${userId}/case/?caseId=${caseId}&caseRoleId=1`)
      .withCredentials()
      .then(() => {
        getCaseUsers();
        setShowSuccessToast(true);
        setSuccessMessage("User added");
      })
      .catch((err: any) => {
        if (err.response && err.response.text) {
          setError(err.response.text)
       }
    });
  };

  const createNewUser = async (e: React.FormEvent<HTMLElement>) => {
    setIsLoading(true);
    e.preventDefault();
    await post(`${process.env.REACT_APP_API_URL}/api/user`)
      .withCredentials()
      .send({...newUserValues, roleId: 1})
      .then(async (res) => {
        await addExistingUser(res.body.id)
        getCaseUsers();
        toggleShowAddUserModal(false);
        setShowSuccessToast(true);
        setSuccessMessage("User created");
      })
      .catch((err: any) => {
        if (err.response && err.response.text) {
          setError(err.response.text)
        }
      });
    setIsLoading(false);
  };

  // Remove user from case study
  const removeUser = async (id: number) => {
    await post(`${process.env.REACT_APP_API_URL}/api/user/remove/${caseId}/?userId=${id}`)
      .withCredentials()
      .then(() => {
        getCaseUsers();
      })
      .catch((err: any) => {
        if (err.response && err.response.text) {
          setError(err.response.text)
        }
      });
    await getUsers();
  };

  // Sends invite to single user
  const sendInvite = async () => {
    if (selectedUser) {
      await post(`${process.env.REACT_APP_API_URL}/api/email/token/${selectedUser.userName}`)
      .withCredentials()
      .then((res: any) => {
        setShowSuccessToast(true);
        setSuccessMessage("Password reset link sent");
      }).catch((err: any) => {
        if (err.response && err.response.text) {
          setError(err.response.text)
        }
      });
      setIsLoading(false);
   }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [caseUsers]);

  return (
    <div className="col">

      <ActionSuccess 
        show={showSuccessToast} 
        message={successMessage}
        setShow={setShowSuccessToast}
      />

      <Modal show={showAddUserModal} onHide={() => toggleShowAddUserModal(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Add participant</h4>
        </Modal.Header>
        <Modal.Body>

          {isAddingNew ? 
            <NewUser 
              createNewUser={createNewUser}
              setIsAddingNew={setIsAddingNew}
              setNewUserValues={setNewUserValues}
              isLoading={isLoading}/>
          : <div>
              <UserListSearch users={nonParticipantUsers} addUser={addExistingUser}/>
              <button 
                onClick={() => {
                  setIsAddingNew(true);
                  setNewUserValues(blankUser);
                }} 
                className="btn btn-sm btn-primary">
                  Create a new user + 
              </button>
            </div>
          }

          <ErrorAlert errorMessage={error}/>

        </Modal.Body>

      </Modal>

      <Modal show={showConfirmEmailModal} onHide={() => setShowConfirmEmailModal(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Send password reset link</h4>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to send a password reset link to <b>{selectedUser ? selectedUser.email : ""}</b>? This will invalidate any previous password reset links that have been sent.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmEmailModal(false)}>
            Cancel
          </Button>
          <Button 
            disabled={isLoading} 
            variant="primary" 
            onClick={() => {
              setIsLoading(true);
              sendInvite();
            }}>
            {isLoading ? <Loader text="Sending..."/> : "Send"}
          </Button>
        </Modal.Footer>

      </Modal>
          
      <p className="float-end">
        <button 
          type="button" 
          className="btn btn-primary mt-2"
          onClick={() => toggleShowAddUserModal(true)}>
          Add + 
        </button>
      </p>
      <h3>Participants</h3>
      <div className="clearfix"></div>

      {caseUsers.length === 0 ? "There are no users assigned to this case yet." :

        <table className="table table-striped user-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email address</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {caseUsers.map((user: User, i) => {
              if (user['CaseUser'].caseRoleId === 1 && user.id !== loggedInUser.id) {
                return (
                  <tr key={i}>
                    <td>{user.userName}</td>
                    <td>{user.email}</td>
                    <td>
                      <button 
                        className="btn btn-sm btn-primary" 
                        onClick={() => {
                          setSelectedUserId(user.id);
                          setShowConfirmEmailModal(true);
                        }}>
                        <i className="bi bi-envelope-fill" title="Send password reset link"></i>
                      </button>
                      &nbsp;
                      <button className="btn btn-sm btn-danger" onClick={() => removeUser(user.id)}>
                        <i className="bi bi-x-circle-fill"></i>&nbsp;&nbsp;Remove
                      </button>
                    </td>
                  </tr>
                );

              }
            })}
          </tbody>
        </table>
      }

    </div>
  );
}

export default Users;
