import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Dropdown, Modal } from "react-bootstrap";
//import { get, post, delete } from "superagent";
import * as superagent from 'superagent';
import { User } from "../../types";

const studentFeedbackLink = "https://forms.office.com/r/m8unUm4NbN?origin=lprLink";
const intructorFeedbackLink = "https://forms.office.com/r/kMnRsQMU7d?origin=lprLink";

function Header({ title, isPaused }: { title: string, isPaused: boolean }) {

  const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);
  const user: User = useSelector((state: any) => state.user)
  const feedbackLink = user.roleId === 1 ? studentFeedbackLink : intructorFeedbackLink;

  const logout = async (e: any) => {

    await superagent.delete(`${process.env.REACT_APP_API_URL}/api/logout`)
      .withCredentials()
      .then(((res: any) => {
        window.location.replace("/");
      }), (err: any) => {
        if (err.response) {
          console.error(err.response)
        }
      })
  };

  return (
    <div className="App">

      <header>

        <nav className={`navbar ${isPaused ? "paused" : ""}`} id="nav-primary">
          <div className="container">
            <div className="d-flex flex-row align-items-end">
              <h2 className="navbar-brand">{title}</h2>
              
              <div className="nav-links">
                {user.id ? 
                  <div className="nav-item">
                    <NavLink to="/account/cases" className={({ isActive }) => isActive ? "active" : ""}>
                      Case Studies
                    </NavLink>
                  </div>
                  : null
                }
                {user.roleId == 3 ? 
                  <div className="nav-item">
                    <NavLink to="/account/users" className={({ isActive }) => isActive ? "active" : ""}>
                      User Management
                    </NavLink>
                  </div>
                  : null
                }
                {user.roleId == 3 || user.roleId == 2 ? 
                  <div className="nav-item">
                    <NavLink to="/account/trash" className={({ isActive }) => isActive ? "active" : ""}>
                      Trash Bin
                    </NavLink>
                  </div>
                  : null
                }
                {user.id ? 
                  <div className="nav-item">
                    <a className="" onClick={() => setOpenHelpModal(true)}>
                      Help
                    </a>
                  </div>
                  : null
                }
              </div>
          </div>

          {isPaused ? <small><i className="bi bi-pause-btn-fill"/>&nbsp;Case study is currently paused.</small> : null}

            {user.userName ? 
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {user.userName}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/account/profile">Profile</Dropdown.Item>
                  <Dropdown.Item onClick={logout}>
                    Logout &nbsp;<i className="bi bi-box-arrow-right"/>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> 
              : null
            }
           </div>

        </nav>

      </header>

      <Modal show={openHelpModal} onHide={() => setOpenHelpModal(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Need Help?</h4>
        </Modal.Header>
        <Modal.Body>
          {/* <p>
            <button className="btn btn-primary btn-sm">View the tutorial</button>
          </p> */}
          <p>To report a bug, provide feedback, or recommend features, please use <a href={feedbackLink} target="_blank">this form</a>.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenHelpModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      
    </div>
  );
}

export default Header;
