
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { get } from "superagent";

import { addUser } from '../../actions/user';
import { User } from "../../types";
import Login from "./login";

function Authorize({ children, roles }: { children: JSX.Element, roles: Array<number> }) {
  const dispatch = useDispatch();
  const [userValidated, setUserValidated] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const user: User = useSelector((state: any) => state.user)

  // Check if user is already logged in
  const checkForSession = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/session/`)
      .withCredentials()
      .then((res: any) => {
        if (roles.includes(res.body.role)) {
          setUserValidated(true);
          dispatch(addUser(res.body.id, res.body.userName, res.body.email, res.body.role));
          setIsReady(true);
        }
      }).catch((err: any) => {
        setUserValidated(false);
      });
  };

  useEffect(() => {
    if (!user.id) {
      checkForSession();
    }
    else {
      setIsReady(true);
    }
  }, []);


  if (isReady) {
    // Return protected child pages
    if (userValidated || user.id) {
      return children;
    }

    // Return login page if not validated
    else {
      return (
        <Login />
      );
    }
  }

  else {
    return null;
  }



}

export default Authorize;
