import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import { Case } from "../../types";
import PaginationControls from "../shared/paginationControls";
import Loader from "../shared/loader";

interface SharedCaseListProps {
  sharedCases: Array<Case>,
  totalCases: number,
  isLoading: boolean,
  getSharedCases: (pageNum: number, orderVal: string, keywordVal: string) => void,
  setSelectedCase: (arg: any) => void,
  importCase: (id: number) => void
};

export const SharedCaseList = ({
  sharedCases, 
  totalCases,
  isLoading, 
  getSharedCases, 
  setSelectedCase,
  importCase
}: SharedCaseListProps) => {
  const [keywordValue, setKeywordValue] = useState<string>("");
  const [orderValue, setOrderValue] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedCaseId, setSelectedCaseId] = useState<number|null>(null);
  const casesPerPage = 8;
  const totalPages = Math.ceil(totalCases / casesPerPage);

  const clearFilters = () => {
    setKeywordValue("");
    setOrderValue("")
    getSharedCases(0, "", "");
  }

  const handleImportCase = (caseId: number) => {
    setSelectedCaseId(caseId);
    importCase(caseId);
  }

  const handleSetCurrentPage = (pageNum: number) => {
    setCurrentPage(pageNum);
    getSharedCases(pageNum * casesPerPage, orderValue, keywordValue);
  }

  return (
    <div>
      <p>Below is a list of case studies shared by other instructors. By importing a case study, a copy will be created and added to your account, allowing you to edit and add your own participants.</p>
        
        <div className="shared-cases-inputs">
          <div className="input-group">
            <span className="input-group-text" id="basic-addon1">
              <i className="bi bi-search"/>
            </span>
            <input 
              type="text"
              className="form-control" 
              value={keywordValue}
              onChange={(e) => {
                setKeywordValue(e.target.value);
                getSharedCases(currentPage * 8, orderValue, e.target.value);
              }}
              placeholder="Search by title or description"/>
          </div>
          <Form.Select value={orderValue} onChange={(e) => {
            setOrderValue(e.target.value);
            getSharedCases(currentPage * 8, e.target.value, keywordValue);
          }}>
            <option value="">Sort by</option>
            <option value="createDate,DESC">Newest - oldest</option>
            <option value="createDate,ASC">Oldest - newest</option>
            <option value="name,ASC">Name (a - z)</option>
            <option value="name,DESC">Name (z - a)</option>
          </Form.Select>
          <button onClick={clearFilters} className="btn btn-sm btn-light" title="Clear filters">
            <i className="bi bi-x-lg"></i>
          </button>
        </div> 

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 case-studies-cards">  
          {sharedCases.map((cs, i) => {
            return (
              <div className="col" key={i}>
                <div className="card h-100">
                  <div className="card-header">
                    <h5>{cs.name}</h5>
                  </div>
                  <div className="card-body">
                    <p>{cs.description}</p>
                    <b>Pages: </b>{cs.pages.length}<br/>
                    <b>Questions: </b>
                    {cs.pages.length > 1 ? cs.pages.reduce((a, b) => {
                      const aTotal = a.questions ? a.questions.length : 0;
                      const bTotal = b.questions ? b.questions.length : 0;
                      return aTotal + bTotal;
                    }) : 0}
                    <br/><br/>
                    <button
                      className="btn btn-sm btn-success case-delete"
                      onClick={() => handleImportCase(cs.id)}>
                        {isLoading && selectedCaseId === cs.id ? 
                          <Loader text="Importing..."/> 
                          : <div>Import <i className="bi bi-download"/></div>
                        }
                    </button>&nbsp;
                    <button className="btn btn-sm btn-primary" onClick={() => setSelectedCase(cs)}>View Details</button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <PaginationControls 
            currentPage={currentPage} 
            setCurrentPage={handleSetCurrentPage} 
            totalPages={totalPages}/>
    </div>
  )

};

export default SharedCaseList;

