export const addUser = (id: number, userName: string, email: string, roleId: number) => ({
  type: 'ADD_USER',
  id,
  userName,
  email,
  roleId
})

export const removeUser = () => ({type: 'REMOVE_USER'});

