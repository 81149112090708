
import { useEffect, useState } from "react";
import { Form, Row, InputGroup, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { patch } from "superagent";

import Header from "./header";
import { User } from "../../types";

function ChangePassword() {

  const user: User = useSelector((state: any) => state.user)
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const passwordsMatch = (newPassword && newPasswordConfirm) && newPassword === newPasswordConfirm;

  const login = async (e: any) => {
    e.preventDefault();
    await patch(`${process.env.REACT_APP_API_URL}/api/user/password/${user.id}`)
      .send({currentPassword, newPassword})
      .withCredentials()
      .then((res: any) => {
        if (res.body.success) {
          setSuccess(true);
        }
      }).catch(err => {
        console.log("ERR", err)
        if (err.response && err.response.text) {
          console.log(err);
          setError(err.response.text);
        }
        else {
          setError("There was an issue creating your password. Please contact your administrator.")
        }
      })
  };

  const validatePassword = () => {
    var passReqRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
    const passwordsValid = passReqRegex.test(newPassword) && passReqRegex.test(newPasswordConfirm);

    if (passwordsMatch && passwordsValid) {
      setValidated(true);
    } 
    else {
      setValidated(false);
    }
    
  }

  useEffect(() => {
    validatePassword();
  }, [newPassword, newPasswordConfirm]);

  return (
    <div>
    
      <Header title="Log into Case Study" isPaused={false}/>

      <main role="main">

        {success ? 
          <div className="container">
            Your password has been saved. <a href="/account/profile">Return to profile</a>.
          </div> :
          <div className="container">
            <a href="/account/profile"><i className="bi bi-arrow-left"/> Back to profile</a>
            <br/><br/>
            <h3>Change your password</h3>
            <br/>

            <div className="row justify-content-md-center change-password-form-container">
                
              <Form validated={validated} onSubmit={login}>
                <Row className="mb-3">
                  <Form.Group controlId="validationPassword">
                    <Form.Label>Current password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="validationPassword">
                    <br/>
                    <Form.Label><span className="text-primary">New password</span></Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setNewPassword(e.target.value)}
                        required/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="validationPasswordConfirm">
                    <br/>
                    <Form.Label><span className="text-primary">Confirm new password</span></Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setNewPasswordConfirm(e.target.value)}
                        required/>
                    </InputGroup>

                  </Form.Group>
                </Row>

                <p className="text-secondary">
                  <b>Requirements: </b>Password must be a minimum of 6 characters long and contain at least one number and one symbol.
                </p>

                <ul>
                  <li 
                    style={{fontWeight: "bold"}} 
                    className={`${passwordsMatch ? "text-success" : "text-danger"}`}>
                      Passwords match &nbsp;
                      <i className={`bi bi-${passwordsMatch ? "check-circle" : "x-circle"}`}/>
                  </li>
                  <li 
                    style={{fontWeight: "bold"}} 
                    className={`${validated ? "text-success" : "text-danger"}`}>
                      Password requirements met &nbsp;
                      <i className={`bi bi-${validated ? "check-circle" : "x-circle"}`}/>
                    </li>
                </ul>

                <div className="text-danger">{error}</div><br />

                <Button type="submit" onClick={login} disabled={!validated}>Save</Button>
              </Form>

            </div>
          </div>
        }

      </main>

    </div>
  );

}

export default ChangePassword;
