import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { get, post } from "superagent";

import ErrorAlert from "../shared/errorAlert";
import ActionSuccess from "../shared/actionSuccess";
import { Case } from "../../types";
import Loader from "../shared/loader";

import SharedCaseList from "./shared-case-list";
import SharedCasePreview from "./shared-case-preview";

interface SharedCasesProps {
  getCaseStudies: () => void;
}

export const SharedCases = ({getCaseStudies}: SharedCasesProps) => {
  const [showSharedCasesModal, setShowSharedCasesModal] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [selectedCase, setSelectedCase] = useState<Case|null>(null);
  const [keywordValue, setKeywordValue] = useState<string>("");
  const [orderValue, setOrderValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [totalCases, setTotalCases] = useState<number>(0);
  const [sharedCases, setSharedCases] = useState<Array<Case>>([]);

  // Retrieves casesPerPage cases at a time
  const getSharedCases = async (offset: number, order: string, keyword: string) => {
    await get(`${process.env.REACT_APP_API_URL}/api/case/shared/?offset=${offset}&order=${order}&keyword=${keyword}`)
      .withCredentials()
      .then((res: any) => {
        setTotalCases(res.body.totalCases);
        setSharedCases(res.body.cases);
        return;
      }).catch((err: any) => {
        if (err.response && err.response.text) {
          setError(err.response.text)
        }
      });
  }

  const importCase = async (caseId: number) => {
    setIsLoading(true);
    await post(`${process.env.REACT_APP_API_URL}/api/case/import/${caseId}`)
    .withCredentials()
    .then(() => {
      getCaseStudies();
      setShowSuccessToast(true);
    })
    .catch((err: any) => {
      if (err.response && err.response.text) {
        setError(err.response.text)
      }
    });
    setIsLoading(false);
  }

  useEffect(() => {
    getSharedCases(0, orderValue, keywordValue);
  }, []);

  return (
    <div>
      <a href="#" onClick={() => setShowSharedCasesModal(true)}>Browse shared cases</a>
      <Modal 
        dialogClassName="modal-90" 
        show={showSharedCasesModal} 
        onHide={() => setShowSharedCasesModal(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Shared Cases</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="shared-cases-container">
            {selectedCase ?
              <SharedCasePreview 
                sharedCase={selectedCase} 
                setSelectedCase={setSelectedCase}
              /> : 
              <SharedCaseList 
                sharedCases={sharedCases} 
                totalCases={totalCases} 
                isLoading={isLoading}
                getSharedCases={getSharedCases} 
                setSelectedCase={setSelectedCase}
                importCase={importCase}/>
            }
          </div>
        </Modal.Body>

        <ErrorAlert errorMessage={error}/>

        <Modal.Footer>
          {selectedCase ? 
            <Button variant="success" onClick={() => importCase(selectedCase.id)}>
              {isLoading ? <Loader text="Importing..."/> : <div>Import <i className="bi bi-download"/></div>}
            </Button>
          : null}

          <Button variant="secondary" onClick={() => setShowSharedCasesModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <ActionSuccess 
        show={showSuccessToast} 
        message={"Case succssfully imported!"}
        setShow={setShowSuccessToast}
      />
    </div>
  )
};

export default SharedCases;

