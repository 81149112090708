import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { get } from "superagent";

import { User } from "../../types";
import RunCase from "../student/runCase";
import EditPage from "../instructor/edit-page";

function ViewPage() {
  const [userCaseRoleId, setUserCaseRoleId] = useState<number|null>(null);
  let { caseId } = useParams();

  const getUserCaseRole = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/case/role/${caseId}`)
      .withCredentials()
      .then((res: any) => {
        setUserCaseRoleId(res.body.roleId);
        return;
      }).catch((err: any) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getUserCaseRole();
  }, []);

  if (userCaseRoleId === 1) {
    return <RunCase />
  }

  if (userCaseRoleId === 2) {
    return <EditPage />
  }

  else return null;

}

export default ViewPage;
