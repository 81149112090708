import { Routes, Route } from "react-router-dom";

import './App.css';
import Authorize from "./components/shared/authorize";
import AuthorizeRedirect from "./components/shared/authorize-redirect";
import EditCasePage from "./components/instructor/edit-case";
import ViewPage from "./components/shared/viewPage";
import PreviewPage from "./components/instructor/preview-page";
import CaseList from "./components/shared/caseList";
import ProfilePage from "./components/shared/profile";
import UsersPage from "./components/admin/users";
import PasswordResetByToken from "./components/shared/passwordResetByToken";
import ChangePassword from "./components/shared/changePassword";
import ForgotPassword from "./components/shared/forgotPassword";
import TrashPage from "./components/instructor/trash";

const App = () => {

  return (
    <div>  

      <Routes>
        <Route path='/account'>
          <Route path="/account/case/:caseId" element={
            <Authorize roles={[2, 3]}>
              <EditCasePage />
            </Authorize>
          }/>
          <Route path="/account/case/:caseId/:pageId" element={
            <Authorize roles={[1, 2, 3]}>
              <ViewPage />
            </Authorize>
          }/>
          <Route path="/account/case/:caseId/:pageId/preview" element={
            <Authorize roles={[2, 3]}>
              <PreviewPage />
            </Authorize>
          }/>
          <Route path="cases" element={
            <Authorize roles={[1, 2, 3]}>
              <CaseList/>
            </Authorize>
          }/>
          <Route path="users" element={
            <Authorize roles={[2, 3]}>
              <UsersPage/>
            </Authorize>
          }/>
          <Route path="trash" element={
            <Authorize roles={[2, 3]}>
              <TrashPage/>
            </Authorize>
          }/>
          <Route path="profile">
            <Route path="" element={
              <Authorize roles={[1, 2, 3]}>
                <ProfilePage/>
              </Authorize>
            }/>
            <Route path="/account/profile/change-password" element={
              <Authorize roles={[1, 2, 3]}>
                <ChangePassword/>
              </Authorize>
            }/>
          </Route>
        </Route>

        <Route path="/password-reset/:token/:userId/:userName" element={<PasswordResetByToken/>}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path='/' element={<AuthorizeRedirect/>}/>

      </Routes> 
    </div>
  );
}

export default App;
