import { get } from "superagent";

import { Case } from "../types";

export const getParticipantCaseStudies: () => Promise<any> = async () => {
  return get(`${process.env.REACT_APP_API_URL}/api/case/participant`)
  .withCredentials()
  .then(res => {
    // Map page statuses to pages
    let caseData: Array<Case> = [];
    res.body.forEach((cs: Case) => {
      cs.pages.forEach(p => {
        p.pagestatuses = cs.users[0].pagestatuses.filter(ps => ps.pageId === p.id);
      });
      caseData.push(cs);
    });
    return {data: caseData, errorMsg: null};
  })
  .catch(err => {
    if (err.response && err.response.text) {
      return {data: null, errrorMsg: err.response.text};
    }
  });
};