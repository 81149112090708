import { useSelector } from "react-redux";

import { User } from "../../types";
import StudentCaseList from "../student";
import InstructorCaseList from "../instructor";

function CaseList() {
  const user: User = useSelector((state: any) => state.user)

  if (user.roleId === 1) {
    return <StudentCaseList />
  }
  
  else return <InstructorCaseList />;

}

export default CaseList;
