
import Header from "../shared/header";
import ParticipantCaseList from "../shared/participantCaseList";

function StudentCaseList() {

  return (
    <div>
      <Header title="Case Study App" isPaused={false}/>
      <main role="main">
        <div className="container">
          <h1>Your Case Studies</h1>
          <ParticipantCaseList/>
        </div>
      </main>
    </div>
  )
}


export default StudentCaseList;
