import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { get, post } from "superagent";

import { User } from "../../types";
import UserListSearch
 from "../shared/user-list-search";

interface CaseSettingsProps {
  caseId: number,
  caseUsers: Array<User>,
  getCaseUsers: any
}

export const CaseSettings = ({caseId, caseUsers, getCaseUsers}: CaseSettingsProps) => {

  const [showAddOwnerForm, setShowAddOwnerForm] = useState(false);
  const loggedInUser: User = useSelector((state: any) => state.user);
  const [instructorList, setInstructorList] = useState<Array<User>>([]);
  const caseOwners: Array<User> = caseUsers.filter((u) => u["CaseUser"].caseRoleId === 2);

  const getInstructors = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/user/instructor`)
      .withCredentials()
      .then((res) => {
        // Filter our instructors who are owners of this case
        setInstructorList(res.body.filter((u: User) => {
          return caseUsers.find((cu: User) => cu.id === u.id) === undefined;
        }));
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const addInstructorAsOwner = async (userId: number) => {
    await post(`${process.env.REACT_APP_API_URL}/api/user/${userId}/case/?caseId=${caseId}&caseRoleId=2`)
      .withCredentials()
      .then(() => getCaseUsers())
      .catch((err: any) => {
        console.error(err);
      });
    setShowAddOwnerForm(false);
  };

  // Remove user from case study
  const removeOwner = async (id: number) => {
    await post(`${process.env.REACT_APP_API_URL}/api/user/remove/${caseId}/?userId=${id}`)
      .withCredentials()
      .then(() => getCaseUsers())
      .catch((err: any) => {
        
      });
  };

  useEffect(() => {
    getInstructors();
  }, []);

  useEffect(() => {
    getInstructors();
  }, [caseUsers]);

  return (
    <div>
      <h5>Case Study Owners</h5>
      <p>You are {caseOwners.length === 1 ? "the only " : "an "} owner of this case study. Owners have all edit rights including: editing, adding and deleting content, adding and removing users, and deleting the case study.</p>
      {caseOwners.length !== 1 && !showAddOwnerForm ? 
        <table className="table table-striped user-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {caseOwners.map((user: any) => {
              return (
                <tr key={user.id}>
                  <td>{user.userName}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.id !== loggedInUser.id ? 
                      <button 
                        className="btn btn-sm btn-danger" 
                        onClick={() => removeOwner(user.id)}>
                        Remove
                      </button>
                      : null
                    }
                    </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        : null
      }

      {showAddOwnerForm ? 
        <div>
          <UserListSearch users={instructorList} addUser={addInstructorAsOwner}/>
          <button 
            className="btn btn-secondary" 
            onClick={() => setShowAddOwnerForm(false)}>
            Cancel
          </button>
        </div>
        : 
        <button 
          className="btn btn-primary" 
          onClick={() => setShowAddOwnerForm(true)}>
            Add additional owner +
        </button>
      }

    </div>
  );
}

export default CaseSettings;