
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "superagent";
import { Alert } from "react-bootstrap";

import Header from "../shared/header";
import ErrorAlert from "../shared/errorAlert";
import Page from "./page";
import { StudentCaseData } from "../../types";
import { socket } from "../..";

function RunCase() {

  const [ caseData, setCaseData ] = useState<StudentCaseData|null>(null);
  const [ isPaused, setIsPaused ] = useState<boolean>(false);
  const [ pageIds, setPageIds ] = useState<Array<number>>([]);
  const [ page, setPage ] = useState<any|undefined>();
  const [ error, setError ] = useState<string>("");

  let { caseId, pageId } = useParams();
  
  // Get and set caseData
  const getCase = async () => {

    await get(`${process.env.REACT_APP_API_URL}/api/case/student/${caseId}`)
    .withCredentials()
    .then((res: any) => { 

      setCaseData(res.body.data.caseData);
      setPageIds(res.body.data.caseData.pageIds);
      setIsPaused(res.body.data.caseData.isPaused);

      // Set case in socket
      socket.emit("set-case", {caseId});

    })
    .catch((err: any) => { console.log(err) });
    
  };

  const getPage = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/page/${pageId}`)
    .withCredentials()
    .then((res: any) => { 
      setPage(res.body.data);
    })
    .catch((err: any) => { 
      if (err.response && err.response.text) {
        setError(err.response.text)
      }
     });
  };

  useEffect(() => {
    getCase();
    getPage();

    socket.on("paused", (data: any) => {
      setIsPaused(data.isPaused)
    });

  }, []);


  return (
    <div>

      <Header title="Case Study App" isPaused={isPaused}/>
      
      {page && caseData ? 
        <Page caseData={caseData} pageData={page} isPaused={isPaused} pageIds={pageIds}/> 
      : null}

      <ErrorAlert errorMessage={error}/>

    </div>
  )
}


export default RunCase;
