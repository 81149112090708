import { useState, useEffect, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';

import { Modal, Button, Form, InputGroup, Toast } from "react-bootstrap";
import { Page } from "../../types";

// >> This is a workaround to get tinymce-react to work with TypeScript
interface EditorFix extends React.Component {}

const TypesciptEditor: any = (Editor as any) as {
  new(): EditorFix;
};

// <<

type TextEditorProps = {
  handleUpdatePage: Function,
  handleSetUpdatedPageValues: Function,
  updatedPageValues: Page
}

const TextEditor = ({
  handleUpdatePage, 
  handleSetUpdatedPageValues, 
  updatedPageValues
}: TextEditorProps) => {
  const [showToast, toggleShowToast] = useState<boolean>(false);
  const [showConfirmDiscardModal, toggleShowConfirmDiscardModal] = useState<boolean>(false);
  const [isEditing, toggleIsEditing] = useState<boolean>(false);

  const editorRef: any = useRef(null);
  
  const saveText = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      handleSetUpdatedPageValues({...updatedPageValues, pageText: content});
      toggleShowToast(true);
      toggleIsEditing(false);
    }
  };

  const discardChanges = () => {
    toggleShowConfirmDiscardModal(false);
    toggleIsEditing(false);
  };

  // Update page after pageText is updated
  useEffect(() => {
    const content = editorRef.current ? editorRef.current.getContent() : null;
    if (content && (content === updatedPageValues.pageText)) {
      handleUpdatePage();
    }
  }, [updatedPageValues.pageText]);

  const cancel = () => {
    
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      // If there are new changes, show confirm dialog
      if ((content || updatedPageValues.pageText) && (content !== updatedPageValues.pageText)) {
        toggleShowConfirmDiscardModal(true);
      }

      else {
        toggleIsEditing(false);
      }
    }
  }

  const props: any = {
    apiKey: "7bfpxowzrz9n74y5h4xuwsuwfzkxkiejvvb8b7kwivc4qbak",
    initialValue: updatedPageValues.pageText,
    disableAutomaticStylesInjection: true,
    readonly: true,
    onInit: (evt: any, editor: any) => editorRef.current = editor
  }

  return (
    <div>
      <div style={{ position: "absolute", bottom: "40px", left: "40px", color: "white" }}>
        <Toast
          onClose={() => toggleShowToast(false)}
          show={showToast}
          animation={true}
          delay={3000}
          bg={'success'}
          autohide>
          <Toast.Body>Updates saved!</Toast.Body>
        </Toast>
      </div>

      <Modal show={showConfirmDiscardModal} onHide={() => toggleShowConfirmDiscardModal(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Cancel</h4>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to discard your changes?
        </Modal.Body>
        <Modal.Footer>
        <Button variant="danger" onClick={discardChanges}>
          Discard
        </Button>
        <Button variant="primary" onClick={() => toggleShowConfirmDiscardModal(false)}>
          Cancel
        </Button>
        </Modal.Footer>
      </Modal>

      <div className="col">
        <p className="float-end">
          <button 
            type="button" 
            className="mt-2 btn btn-primary"
            style={{opacity: isEditing ? 0 : 1}}
            onClick={() => toggleIsEditing(true)}>
            Edit Page Content
          </button>
        </p>
        <h3>Page Content</h3>
        <div className="clearfix"></div>
      </div>

      <div style={{display: isEditing ? "initial" : "none"}}>
        <TypesciptEditor {...props}/>
        <br/>
        <button className="btn btn-primary" onClick={saveText}>Save</button>
        &nbsp;
        <button className="btn btn-secondary" onClick={cancel}>Cancel</button>
      </div>

      {updatedPageValues.pageText ? 
        <div className="page-text-preview" style={{display: isEditing ? "none" : "block"}}>
          {parse(updatedPageValues.pageText)}
        </div> : null
      }

      {!updatedPageValues.pageText && !isEditing ? <p>You have no page content yet.</p> : null}
      
    </div>
  );
}

export default TextEditor;
