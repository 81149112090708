
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import { Document, DocumentsByPage } from "../../types";
import { fileExtensions } from "../../helpers/fileExtensions";

type DocumentsLinkProps = {
  documentsByPage: DocumentsByPage,
  caseId: number
}

const AllDocuments = ({ documentsByPage, caseId }: DocumentsLinkProps) => {
  const [showDocsList, toggleShowDocsList] = useState(false);

  return (
    <div>

      <Modal show={showDocsList} onHide={() => toggleShowDocsList(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">All Documents</h4>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(documentsByPage).map((pageId: string) => {
            const page = documentsByPage[pageId]
            
            if (page.documents.length > 0) {
              return (
                <div key={pageId}>
                  <h5>{page.title}</h5>
                  <table className="table table-sm table-striped table-hover doc-table">
                    <thead>
                      <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Type</th>
                        <th scope="col">Size</th>
                      </tr>
                    </thead>
                    <tbody>
  
                      {page.documents.map((doc: Document) => {
                        const extensionIcon = fileExtensions[doc.extension] ? fileExtensions[doc.extension] : "bi-question-circle";

                        return (
                          <tr key={doc.id}>
                            <td>
                              <a href={`${process.env.REACT_APP_API_URL}/api/document/${doc.id}`} target="__blank">{doc.title}</a>
                              </td>
                            <td><i className={`bi ${extensionIcon}`}/></td>
                            <td>{doc.size}</td>
                          </tr>
                        )
                      })}
                     </tbody>
                  </table>
                </div>
              )
            }
          })} 
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={e => toggleShowDocsList(false)}>Close</Button>
        </Modal.Footer>
      </Modal> 
  
      <a href="#" onClick={() => toggleShowDocsList(true)}>View all documents</a> 
        
    </div> 
  );
  
}

export default AllDocuments;
