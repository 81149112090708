import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

interface InfoPopupProps {
  title: string,
  message: string
}

function InfoButtonPopup({ title, message }: InfoPopupProps) {
  const [ showModal, setShowModal ] = useState<boolean>(false);

  return (
    <div className="info-button-container">
      <i className="bi bi-info-circle clickable" onClick={() => setShowModal(true)}/>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">{title}</h4>
        </Modal.Header>
        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

};

export default InfoButtonPopup;
