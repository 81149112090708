import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Alert, Modal, Button, Form } from "react-bootstrap";
import { get, put } from "superagent";

import PageDragAndDrop from "./page-drag-and-drop";
import Users from "./users";
import Results from "./results";
import Header from "../shared/header";
import InfoButtonPopup from "../shared/infoButtonPopup";
import ActionSuccess from "../shared/actionSuccess";
import CaseSettings from "./case-settings";

import { Case, User } from "../../types";
import { socket } from "../..";

function EditCasePage() {

  let { caseId } = useParams();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [caseStudy, setCaseStudy] = useState<Case|null>(null);
  const [caseUsers, setCaseUsers] = useState<Array<User>>([]);
  const [editingName, toggleEditName] = useState(false);
  const [editingDescription, toggleEditDescription] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [nameValue, setNameValue] = useState<string>();
  const [descriptionValue, setDescriptionValue] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);

  // Get all case studies
  const getCase = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/case/${caseId}`)
      .withCredentials()
      .then((res: any) => {
        setCaseStudy(res.body.data);
        return;
      }).catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });
  };

  // Get users in case
  const getCaseUsers = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/user/case/${caseId}`)
    .withCredentials()
    .then((res: any) => {
      setCaseUsers(res.body) 
    }).catch((err: any) => {
      
    });
  }

  const updateCase = async (key: string, value: any) => {
    if (caseStudy) {
      const caseData: {[key: string]: any} = {
        "name": caseStudy.name,
        "description": caseStudy.description,
        "isShareable": caseStudy.isShareable
      };
      caseData[key] = value;

      await put(`${process.env.REACT_APP_API_URL}/api/case/${caseId}`)
        .withCredentials()
        .send(caseData)
        .then(async () => {
          await getCase();
          if (key === "isPaused") {
            setShowSuccessToast(true);
            setSuccessMessage(value ? "Case study has been paused" : "Cause study has been resumed")
          }
        })
        .catch((err: any) => {
          if (err.response) {
            setShowAlert(true);
            setAlertMessage(err.response.text);
          }
          console.error(err);
        });
      toggleEditName(false);
      toggleEditDescription(false);
    }
  };

  const pause = (pause: boolean) => {
    socket.emit("pause", {caseId, pause});
  }

  // Converts date string to MM/DD/YYYY HH:SS
  const getReadableDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString([], { hour: '2-digit', minute: '2-digit' });
  }

  useEffect(() => {
    getCase();
    getCaseUsers();
  }, []);

  useEffect(() => {
    if (caseStudy) {
      setIsPaused(caseStudy.isPaused);
      setNameValue(caseStudy.name);
      setDescriptionValue(caseStudy.description);
    }
  }, [caseStudy]);

  return (
    <div>

      <Header title="Case Study Builder" isPaused={false}/>

      <main role="main">


        {caseStudy ?

          <div className="container">

            <Modal dialogClassName="modal-70" show={showSettingsModal} onHide={() => setShowSettingsModal(false)}>
              <Modal.Header closeButton>
                <h4 className="modal-title">Case Study Settings</h4>
              </Modal.Header>
              <Modal.Body>
                <p>Changes are automatically saved.</p>
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    checked={caseStudy.isShareable}
                    onChange={(e) => updateCase("isShareable", e.target.checked)}/>
                  <label className="form-check-label d-flex">
                    Allow this case study to be shared
                  </label>
                  <div className="form-text">
                    Sharing allows other instructors to make a copy of this case study. If you don't mark it as shared, only you and participants of this case can view it.
                  </div>
                </div>
                <br/>
                <div>
                  <CaseSettings caseId={caseStudy.id} caseUsers={caseUsers} getCaseUsers={getCaseUsers}/>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowSettingsModal(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="row">

              <div className="col">
                <p><a href="/account/cases">&laquo; Back to all Case Studies</a></p>
              </div>

              {/* Right-hand pause container */}
              <div className="col">

                <div className="float-end">
                  <div className="d-flex align-items-center">
                    <button className="btn btn-sm btn-primary" onClick={() => setShowSettingsModal(true)}>
                      <i className="bi bi-gear-fill"/> &nbsp;
                      Settings
                    </button>
                    {isPaused ? 
                      null : 
                      <div>
                        &nbsp;&nbsp;
                        <button 
                          className={`btn btn-sm btn-warning`} 
                          onClick={() => {
                            pause(true);
                            updateCase("isPaused", true);
                          }}>
                          <i className="bi bi-pause"/>
                          &nbsp;Pause Case Study
                        </button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              
              <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                <Alert.Heading>Error</Alert.Heading>
                <p>{alertMessage}</p>
              </Alert> 

              <div>

                {/* Metadata container */}
                <div className="col">

                  {/* Edit name */}
                  {editingName ? 
                    <div className="edit-name-container">
                      <label className="form-label">Edit case study name:</label><br/>
                      <input 
                        className="form-control mb-2"
                        defaultValue={nameValue} 
                        onChange={(e) => setNameValue(e.target.value)}/>
                      <button 
                        className="btn btn-sm btn-primary" 
                        onClick={() => updateCase("name", nameValue)}>
                        <i className="bi bi-pencil-square"></i>&nbsp;&nbsp;Save
                      </button>&nbsp;
                      <button 
                        className="btn btn-sm btn-secondary" 
                        onClick={() => toggleEditName(false)}>
                        <i className="bi bi-x-circle"/>&nbsp;&nbsp;Cancel
                      </button>
                    </div> :
                    <h2 style={{marginTop: "0px"}}>
                      {caseStudy.name} <i className="bi bi-pencil-square" onClick={() => toggleEditName(true)}/>
                    </h2>
                  }

                  <p style={{fontSize: "14px"}}>
                    Last modified: {getReadableDate(caseStudy.modifyDate)}
                  </p>

                  {caseStudy.isPaused ? 
                    <div className="alert alert-warning d-flex justify-content-between align-items-center" role="alert">
                      <div>
                        <div className="d-flex align-items-center">
                          <div>
                            <i className="bi bi-exclamation-circle-fill"/>
                            &nbsp; This case study is currently paused. &nbsp; &nbsp; 
                          </div>     
                          <button 
                            className={`btn btn-sm btn-secondary`} 
                            onClick={() => {
                              pause(false);
                              updateCase("isPaused", false);
                            }}>
                            <i className="bi bi-play"/>
                            &nbsp;Resume Case Study
                          </button>
                          &nbsp;&nbsp;
                        </div>
                      </div>
                      <InfoButtonPopup 
                        title="Resume Case Study" 
                        message={`This case study is currently paused. Users cannot answer questions or switch pages until it's resumed. `}/>
                    </div>
                  : null}
                  
                  {/* Edit description */}
                  { editingDescription ? 
                    <div className="case-description-edit">
                      <label className="form-label">Edit case study description:</label>
                      <input 
                        type="email" 
                        className="form-control" 
                        id="editDescription" 
                        value={descriptionValue}
                        onChange={(e) => setDescriptionValue(e.target.value)}/>
                      <button 
                        className ="btn btn-sm btn-primary" 
                        onClick={() => updateCase("description", descriptionValue)}>
                        <i className="bi bi-pencil-square"/>&nbsp;&nbsp;Save
                      </button>&nbsp;
                      <button 
                        className="btn btn-sm btn-secondary" 
                        onClick={() => toggleEditDescription(false)}>
                        <i className="bi bi-x-circle"/>&nbsp;&nbsp;Cancel
                      </button>
                    </div> :
                    <div className="case-description-display">
                        <span><b>Description: </b>{caseStudy.description + " "}</span>
                        <i onClick={() => toggleEditDescription(true)} className="bi bi-pencil-square"/>
                    </div>
                  }

                  <hr className="my-4"/>

                  <div className="row row-cols-2 gx-5">
                    <PageDragAndDrop />
                    <Users caseId={caseStudy.id} caseUsers={caseUsers} getCaseUsers={getCaseUsers}/>
                  </div>

                  <Results/>

                  <br />
                </div>
              </div> 
            </div>
          </div>
        : null}

        <ActionSuccess 
          show={showSuccessToast} 
          message={successMessage}
          setShow={setShowSuccessToast}
        />
        
      </main>
    </div>
  );
}

export default EditCasePage;
