import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";

function ErrorAlert({ errorMessage }: {errorMessage: string}) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (errorMessage) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [errorMessage]);

  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Error</Alert.Heading>
        <p>{errorMessage}</p>
      </Alert>
    );
  }

  return null;
  
}

export default ErrorAlert;
