
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Alert } from "react-bootstrap";
import { get } from "superagent";
import parse from 'html-react-parser';

import Header from "../shared/header";
import Timer from "../student/timer";
import { Case, Page, Question, Choice } from "../../types";
import PageDocuments from "../student/page-documents";

type QuestionsProps = {
  questions: Array<Question>;
  isPaused: boolean;
  timeIsUp: boolean;
  handleSetQuestionsComplete: (isComplete: boolean) => void;
}

type QuestionAnswers = {
  [questionId: number]: {
    answer: string,
    submitted: boolean
  }
}

const Questions = ({ 
  questions, 
  isPaused, 
  timeIsUp, 
  handleSetQuestionsComplete
 }: QuestionsProps) => {

  const [verifying, toggleVerify] = useState(false);
  const [feedback, setFeedback] = useState<string>("");
  const [showingLimitMessage, toggleShowingLimitMessage] = useState<boolean>(false);
  const [showingFeedback, toggleShowingFeedback] = useState<boolean>(false);
  const [currentQuestionId, setCurrentQuestionId] = useState<number>(questions[0].id);
  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswers>({});

  // Submit response
  const handleSubmit = async (e: any) => {
    toggleVerify(false);

    let newQuestionAnswers: QuestionAnswers = Object.assign({}, questionAnswers);
    newQuestionAnswers[currentQuestionId] = {
      answer: newQuestionAnswers[currentQuestionId].answer, 
      submitted: true
    };

    setQuestionAnswers(newQuestionAnswers);
    const currentQuestionIndex = Object.keys(questionAnswers).findIndex(id => id === currentQuestionId.toString());
    const nextQuestionId = Object.keys(questionAnswers)[currentQuestionIndex + 1];
    const question: any = questions.find((q: Question) => q.id === currentQuestionId);

    // If all answers complete:
    const allQuestionsAnswered = Object.keys(newQuestionAnswers).find((k: any) => questionAnswers[k].submitted === true);
   
    if (allQuestionsAnswered) {
      handleSetQuestionsComplete(true);
    }

    if (showingLimitMessage) {
      toggleShowingLimitMessage(false);
    }

    // Set next question ID
    if (nextQuestionId) {
      setCurrentQuestionId(parseInt(nextQuestionId));
    }

    // Show feedback if any
    if (question) {
      let isCorrect = false;
      let responseText = newQuestionAnswers[currentQuestionId].answer;

      // Check if multiple choice is correct
      if (question.questionTypeId === 2 && question.answer) {
        isCorrect = question.answer === responseText;
      }

      // Check if text question contains keywords
      else if (question.questionTypeId === 1 && question.keywords) {
        const keywordsArray = question.keywords.split(",");
        // Check that response text contains all keywords
        isCorrect = keywordsArray.map((word: string) => responseText.includes(word)).every((result: boolean) => result === true);
      }

      if (isCorrect && question.positiveFeedback) {
        toggleShowingFeedback(true);
        setFeedback(question.positiveFeedback);
      }

      if (!isCorrect && question.negativeFeedback) {
        toggleShowingFeedback(true);
        setFeedback(question.negativeFeedback);
      }

    }

  }

  const handleUpdateQuestionAnswer = (questionId: number, answer: string) => {
    let newQuestionAnswer: QuestionAnswers = Object.assign({}, questionAnswers);
    newQuestionAnswer[questionId] = {answer, submitted: false};
    setQuestionAnswers(newQuestionAnswer);
  }

  const handleUpdateTextarea = (e: any, questionId: number) => {
    // Prevent limit from reaching 2,000 characters
    if (e.target.value.length > 2000) {
      e.preventDefault();
      toggleShowingLimitMessage(true);
      return;
    }

    else if (e.target.value.length <= 2000 && showingLimitMessage) {
      toggleShowingLimitMessage(false);
    }

    // Auto-resize textarea
    var offset = e.target.offsetHeight - e.target.clientHeight;
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + offset + 'px';

    handleUpdateQuestionAnswer(questionId, e.target.value);
  }

  // Handles displaying the different question types
  const displayQuestion = (q: Question) => {
    // Cannot answer if not current question or response exists
    const answeringDisabled = questionAnswers[q.id].submitted || isPaused || timeIsUp || currentQuestionId !== q.id;

    // text question
    if (q.questionTypeId === 1 && questionAnswers[q.id]) {
      return (
        <form>
          <div className="mb-3">
            <label className="form-label">{q.questionText}</label>
            <textarea 
              onChange={(e) => handleUpdateTextarea(e, q.id)}
              rows={2}
              style={{resize: "none"}}
              className="form-control" 
              placeholder="Type your answer here."
              value={questionAnswers[q.id].answer}
              disabled={answeringDisabled}
            />
            <br/>
            {showingLimitMessage ? <p className="text-danger">Character limit reached</p> : null}
            <button 
              onClick={(e) => {
                e.preventDefault();
                setCurrentQuestionId(q.id);
                toggleVerify(true);
              }}
              className="btn btn-primary"
              disabled={answeringDisabled}>
                Submit
            </button>
          </div>
        </form>
      );
    }

    // Multiple choice question
    if (q.questionTypeId === 2 && questionAnswers[q.id]) {
      return (
        <div>
          <p><b>{q.questionText}</b></p>
          {q.choices.map((choice: Choice, i: number) => {
            return (
              <button 
                key={i}  
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentQuestionId(q.id);
                  handleUpdateQuestionAnswer(q.id, choice.name);
                  toggleVerify(true)
                }}
                style={{marginRight: "20px", border: questionAnswers[q.id].answer === choice.name ? "2px solid #4f4f4f" : "0px"}}
                className="btn btn-primary"
                disabled={answeringDisabled}>
                  {choice.name}
              </button>
            )
          })}
        </div>
      )
    }

  }

  useEffect(() => {
    // Map through questions to make an array to keep track of answers
    let newQuestionAnswers: QuestionAnswers = {};

    questions.forEach(q => {
      newQuestionAnswers[q.id] = { answer: "", submitted: false}
    });

    setQuestionAnswers(newQuestionAnswers)

  }, []);

  return (
    <div className="questions">

      <h2>Questions</h2>
      
      <Modal show={showingFeedback} onHide={() => toggleShowingFeedback(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Feedback</h4>
        </Modal.Header>
        <Modal.Body>
          <p>{feedback}</p>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => toggleShowingFeedback(false)}>Ok</Button>
          </Modal.Footer> 
      </Modal>

      <Modal show={verifying} onHide={() => toggleVerify(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Please confirm</h4>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to submit this question? You cannot edit your answer once it's been submitted.</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={e => handleSubmit(e)}>Submit</Button>
        </Modal.Footer> 
      </Modal>

      {questions.map((q: Question) => {
        return (
          <div className="row" key={q.id} style={{marginBottom: "20px"}}>
            {questions.length === Object.keys(questionAnswers).length ? displayQuestion(q) : null}
          </div>
        )
       })}

      <hr/>

    </div>
  );
}

function PreviewPage() {

  const [ caseData, setCaseData ] = useState<Case>();
  const [ pageData, setPageData ] = useState<Page>();
  const [ timeRemain, setTimeRemain ] = useState<number>(0);
  const [ showPageFeedback, toggleShowPageFeedback ] = useState<boolean>(false);
  const [ showCantProceedMessage, toggleShowCantProceedMessage ] = useState<boolean>(false);
  const [ questionsComplete, toggleQuestionsComplete ] = useState<boolean>(false);

  let { caseId, pageId } = useParams();
  
  // Get and set caseData
  const getCase = async () => {

    await get(`${process.env.REACT_APP_API_URL}/api/case/${caseId}`)
    .withCredentials()
    .then((res: any) => { 
      setCaseData(res.body.data);
    })
    .catch((err: any) => { console.log(err) });
    
  };

  const getPage = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/page/${pageId}`)
    .withCredentials()
    .then((res: any) => { 
      setPageData(res.body.data);
      setTimeRemain(res.body.data.time ? res.body.data.time * 60 : 0);
    })
    .catch((err: any) => { console.log(err) });
  }

  useEffect(() => {
    getCase();
    getPage();
  }, []);

  if (caseData && pageData) {

    const currentPageIndex = caseData.pages.findIndex(p => p.id === pageData.id);
    const hasQuestions = pageData.questions.length > 0;
    const pageHasTime = pageData.time !== null;
    const hasUntimedQuestionsLeft = hasQuestions && !questionsComplete && !pageHasTime;
    const hasTimedQuestionsLeft = hasQuestions && !questionsComplete && pageHasTime && timeRemain > 0;

    return (
      <div style={{marginBottom: "200px"}}>

        <Modal show={showPageFeedback} onHide={() => toggleShowPageFeedback(false)}>
          <Modal.Header closeButton><h4 className="modal-title">Message:</h4></Modal.Header>
          <Modal.Body><p>{pageData.feedback}</p></Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => toggleShowPageFeedback(false)}>Ok</Button>
          </Modal.Footer> 
        </Modal>

        <Modal show={showCantProceedMessage} onHide={() => toggleShowCantProceedMessage(false)}>
          <Modal.Header closeButton>
            <h4 className="modal-title">Can't proceed</h4>
          </Modal.Header>
          <Modal.Body>
            <p>Please complete all exercises on this page before proceeding.</p>
          </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => toggleShowCantProceedMessage(false)}>Ok</Button>
            </Modal.Footer> 
        </Modal>

        <Header title={caseData.name} isPaused={caseData.isPaused} />
        
        <nav className="navbar" id="nav-secondary">
          <div className="container">

          {currentPageIndex > 0 ?
            <button className="btn btn-primary" disabled={caseData.isPaused}>&laquo; Previous</button>
          : null }

          <div>Page 1/1</div>

          {timeRemain ? 
            <Timer 
              pageId={pageData.id} 
              timeRemain={timeRemain} 
              isPaused={caseData.isPaused} 
              setPreviewTimeRemain={setTimeRemain}
              isPreviewing={true}/> 
          : null}

            {currentPageIndex < (caseData.pages.length - 1) ?
              <a href="#" onClick={(e: any) => {
                e.preventDefault();
                if (hasUntimedQuestionsLeft || hasTimedQuestionsLeft || caseData.isPaused) {
                  toggleShowCantProceedMessage(true);
                }
                else if (pageData.feedback) {
                  toggleShowPageFeedback(true);
                }
              }}>
                <button 
                  className="btn btn-primary " 
                  disabled={hasUntimedQuestionsLeft || hasTimedQuestionsLeft || caseData.isPaused}>
                    Next &raquo;
                </button>
              </a>
            : null}

          </div>
        </nav>

        <main role="main">
          <div className="container">

            <h1>{pageData.title}</h1>
            {pageData.pageText ? parse(pageData.pageText) : null}

            { pageData.documents && pageData.documents.length > 0 ? 
              <PageDocuments documents={pageData.documents} caseId={caseData.id.toString()}/> 
            : null }

            { pageData.questions && pageData.questions.length > 0 ? 
              <Questions 
                questions={pageData.questions} 
                isPaused={caseData.isPaused} 
                timeIsUp={pageHasTime ? timeRemain === 0 : false}
                handleSetQuestionsComplete={toggleQuestionsComplete}
              />
            : null}

          </div>
        </main>

        <div className="alert-bottom-banner-container">
          
          <Alert variant="warning">
            <div className="container">
              <Alert.Heading>Page Preview</Alert.Heading>
              <p>
                You are currently previewing this page as a student.
              </p>
              <button className="btn btn-warning" onClick={() => window.close()}>Exit</button>
            </div>
          </Alert>
        </div>

      </div>
    )

  }

  else {
    return (
      <div></div>
    )
  }
 
}

export default PreviewPage;
